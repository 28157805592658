import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Location from 'models/Location'
import LocationService from './LocationService'
import PendingImage from 'models/PendingImage'
import ImageService from 'services/ImageService'
import { Zoom } from 'react-toastify'
import { toast } from 'react-toastify'

export function useLocationEdit(locationId: string) {
    const [location, setLocation] = useState<Location | null>(null)
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [errors, setErrors] = useState<Record<string, any>>({})
    const history = useHistory()

    useEffect(() => {
        const loadLocation = async () => {
            setLoading(true)
            try {
                if (locationId === 'new') {
                    setLocation(new Location())
                } else {
                    const loc = await LocationService.get(locationId)
                    if (!loc.hasRight('FULL')) history.push(loc.$detailUrl)
                    setLocation(loc)
                }
            } catch (e) {
                console.error(e)
            } finally { 
                setLoading(false)
            }
        }
        loadLocation()
    }, [locationId, history])

    const validate = () => {
        if (!location) return false
        const _errors = location.validate()
        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!location) return
        setSaving(true)
        try {
            if (!validate()) {
                toast.error('Gegevens incorrect', {
                    containerId: 'B',
                    transition: Zoom,
                    autoClose: 2000,
                })
                return
            }
            
            // Upload logo if it's a pending image
            if (location.$logo instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: location.$logo.$file,
                    name: `${location.$name} - LOGO`,
                    type: 'LOGO',
                })
                location.$logo = image
            }

            // Upload banner if it's a pending image
            if (location.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: location.$banner.$file,
                    name: `${location.$name} - BANNER`,
                    type: 'BANNER',
                })
                location.$banner = image
            }


            let savedLocation: Location
            if (locationId === 'new') {
                savedLocation = await LocationService.post(location)
            } else {
                savedLocation = await LocationService.update(location)
            }
            toast.success('Opgeslagen', {
                containerId: 'B',
                transition: Zoom,
                autoClose: 2000,
            })
            history.push(savedLocation.$detailUrl)
        } catch (e) {
            console.error(e)
        } finally {
            setSaving(false)
        }
    }

    const cancel = () => {
        const dashboardUrl = '/dashboard?tab=locations'
        if (locationId === 'new') {
            history.push(dashboardUrl)
        } else {
            history.push(location?.$detailUrl || dashboardUrl)
        }
    }

    return {
        location,
        loading,
        saving,
        errors,
        save,
        cancel,
    }
} 