import MKTypography from 'components/MaterialKit/MKTypography'
import Markdown from 'react-markdown'

export const MarkdownViewer = ({ content }: { content: string }) => {
    const components = {
        h1: ({ node, ...props }: any) => (
            <MKTypography
                variant="h3"
                color="info"
                className="mb-3"
                style={{ fontWeight: 500, fontSize: '1.75rem' }}
                {...props}
            />
        ),
        h2: ({ node, ...props }: any) => (
            <MKTypography
                variant="h4"
                color="info"
                className="mb-3"
                style={{ fontWeight: 400, fontSize: '1.5rem' }}
                {...props}
            />
        ),
        h3: ({ node, ...props }: any) => (
            <MKTypography
                variant="h5"
                color="info"
                className="mb-3"
                style={{ fontWeight: 300, fontSize: '1.25rem' }}
                {...props}
            />
        ),
        p: ({ node, ...props }: any) => (
            <MKTypography variant="body2" className="mb-2" color="text" {...props} />
        ),
        ul: ({ node, ...props }: any) => <ul className="mb-2 ml-4 list-disc" {...props} />,
        li: ({ node, ...props }: any) => <li className="my-1" {...props} />,
        pre: ({ node, ...props }: any) => <div className="bg-gray-100 rounded-md p-2" {...props} />,
        code: ({ node, ...props }: any) => <p className="" {...props} />,
    }
    return (
        <>
            <Markdown components={components} className="leading-5">
                {content}
            </Markdown>
        </>
    )
}
