import { Tooltip, useMediaQuery } from '@mui/material'
import MuiAvatarGroup from '@mui/material/AvatarGroup'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKTypography from 'components/MaterialKit/MKTypography'
import CustomLineupSpot from 'models/CustomLineupSpot'
import Event from 'models/Event'
import User from 'models/User'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { MicIcon } from '../CKIcons'
import './style.scss'
interface Props {
    event: Event
}

// function union(a: any[], b: any[]) {
//     const c = a.concat(b).sort()
//     const res = c.filter((value, pos) => {
//         return c.indexOf(value) === pos
//     })
//     return res
// }

function shuffle(array): any[] {
    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
}

const CKLineupDetail: FC<Props> = ({ event }) => {
    const mcSpotId = event.$spots.find((spot) => spot.$type === 'MC')?.$id

    const lineUp = event.$lineUp.filter((user) => user.pivot.event_spot_id !== mcSpotId)
    const customLineup =
        event.$custom_lineup?.length > 0
            ? event.$custom_lineup.filter((user) => user.$isMc === false)
            : []
    const actualLineUp = shuffle([...lineUp, ...customLineup])

    const mcs = event.$lineUp.filter((user) => user.pivot.event_spot_id === mcSpotId)
    const customMcs =
        event.$custom_lineup?.length > 0
            ? event.$custom_lineup?.filter((user) => user.$isMc === true)
            : []
    const actualMcs = shuffle([...mcs, ...customMcs])

    const isMobile = useMediaQuery('(max-width:768px)')
    const history = useHistory()

    const size = isMobile ? 'lg' : 'xl'

    const UserBubble = ({ user, isMC }: { user: User | CustomLineupSpot; isMC?: boolean }) => {
        const key = 'event-lineup-' + event.$id + '-' + user.$id

        return (
            <Tooltip key={key} title={user.$name} arrow={false} placement="top">
                <div
                    className="d-flex flex-column align-items-center justify-content-center event-lineup-avatar mx-1"
                    onClick={(e) => {
                        e.preventDefault()
                        if (user instanceof CustomLineupSpot) return
                        history.push(user.$detailUrl)
                    }}
                >
                    <MKAvatar
                        className="me-1"
                        src={user.$pictureUrl}
                        alt={user.$name}
                        size={size}
                    />
                    <MKTypography
                        variant="caption"
                        color="text"
                        style={{
                            marginLeft: '-8px',
                            fontWeight: isMC && 'bold',
                        }}
                    >
                        {user.$first_name} {isMC && user.$last_name}
                    </MKTypography>
                </div>
            </Tooltip>
        )
    }

    return (
        <>
            <div className="py-2 mb-3">
                {/* <MKTypography
                    fontWeight="bold"
                    variant="label"
                    color="info"
                    className="mb-2 d-flex align-items-center justify-content-center"
                    style={{ lineHeigth: '2rem' }}
                >
                    <MicIcon className="me-2"></MicIcon>
                    Line-up:
                </MKTypography> */}
                <div className="d-flex justify-content-center flex-column align-items-center">
                    {actualLineUp && (
                        <MuiAvatarGroup
                            max={8}
                            className="d-flex align-items-center mb-2 flex-wrap justify-content-center"
                            style={{ marginLeft: '12px' }}
                        >
                            {actualLineUp.map((user) => {
                                return <UserBubble key={`user-bubble-${user.$id}`} user={user} />
                            })}
                        </MuiAvatarGroup>
                    )}
                </div>
                {actualMcs?.length > 0 && (
                    <>
                        <MKTypography
                            fontWeight="bold"
                            variant="label"
                            color="info"
                            className="mt-2 d-flex align-items-center justify-content-center"
                            style={{ lineHeigth: '2rem' }}
                        >
                            <MicIcon className="me-2"></MicIcon>
                            MC:
                        </MKTypography>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <MuiAvatarGroup
                                max={20}
                                className="d-flex align-items-center mb-2 flex-wrap justify-content-center"
                                style={{ marginLeft: '12px' }}
                            >
                                {actualMcs.map((user) => {
                                    return <UserBubble user={user} key={`user-bubble-${user.$id}`} />
                                })}
                            </MuiAvatarGroup>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default CKLineupDetail
