import { faLocationDot, faMicrophoneLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EditIcon from '@mui/icons-material/Edit'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import { Badge, useMediaQuery } from '@mui/material'
import logoDark from 'assets/images/logos/logo_dark.png'
import logoLight from 'assets/images/logos/logo_light.png'
import MKBox from 'components/MaterialKit/MKBox'
import { useDarkMode } from 'contexts/DarkModeContext'
import { useNotificationsContext } from 'contexts/NotificationsContext'
import { useUserContext } from 'contexts/UserContext'
import DefaultNavbar from 'examples/Navbars/DefaultNavbar'
import { FC, useEffect, useMemo, useState } from 'react'
import AuthService from 'services/AuthService'
import UserService from 'services/UserService'
import { CircleNotificationsIcon, EventIcon, WorkshopIcon } from '../CKIcons'
import './style.scss'

const CKNavbar: FC = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const userContext = useUserContext()
    const { unreadCount: unreadNotifications } = useNotificationsContext()
    const [unreadMessages, setUnreadMessages] = useState(0)
    const darkMode = useDarkMode()

    const routes = useMemo(() => {
        const result: {
            name: any
            route?: string
            collapse?: any[]
            divider?: boolean
            action?: any
        }[] = [
            {
                name: (
                    <span className="d-flex align-items-center">
                        <EventIcon className="me-1" /> Events
                    </span>
                ),
                route: '/',
            },
            {
                name: (
                    <span className="d-flex align-items-center">
                        <FontAwesomeIcon
                            fontSize="0.75rem"
                            style={{ width: '0.75rem' }}
                            icon={faMicrophoneLines}
                            className="me-1"
                        />
                        Artiesten
                    </span>
                ),
                route: '/artists',
            },
            {
                name: (
                    <span className="d-flex align-items-center">
                        <FontAwesomeIcon
                            fontSize="0.75rem"
                            style={{ width: '0.75rem' }}
                            icon={faLocationDot}
                            className="me-1"
                        />
                        Locaties
                    </span>
                ),
                route: '/locations',
            },
            {
                name: (
                    <span className="d-flex align-items-center">
                        <WorkshopIcon className="me-1" />
                        Workshops
                    </span>
                ),
                route: '/workshops',
            },
        ]

        if (userContext?.user) {
            result.push({
                name: 'DIVIDER',
                divider: true,
            })
            result.push({
                name: (
                    <Badge badgeContent={unreadNotifications} color="info">
                        <span className="d-flex align-items-center">
                            <CircleNotificationsIcon fontSize="medium" />
                        </span>
                    </Badge>
                ),
                route: `/notifications`,
            })
            result.push({
                name: (
                    <span className="d-flex align-items-center">
                        <PersonIcon className="me-1" />
                    </span>
                ),
                collapse: [
                    {
                        name: (
                            <span className="d-flex align-items-center">
                                <AccountCircleIcon className="me-1" /> Mijn Profiel
                            </span>
                        ),
                        route: `/user/${userContext.user.$slug}`,
                    },
                    {
                        name: (
                            <span className="d-flex align-items-center">
                                <EditIcon className="me-1" /> Profiel Aanpassen
                            </span>
                        ),
                        route: `/admin/user/${userContext.user.$slug}`,
                    },
                    {
                        name: (
                            <span className="d-flex align-items-center">
                                <SettingsIcon className="me-1" /> Instellingen
                            </span>
                        ),
                        route: `/settings`,
                    },
                    {
                        name: (
                            <span className="d-flex align-items-center">
                                <LogoutIcon className="me-1" />
                                Log uit
                            </span>
                        ),
                        action: async () => {
                            await AuthService.logout()
                            window.location.href = '/'
                        },
                    },
                ],
            })
            result.push({
                name: (
                    <span className="d-flex align-items-center">
                        <DashboardIcon className="me-1" /> Beheer
                    </span>
                ),
                route: `/dashboard`,
            })
            result.push({
                name: (
                    <Badge badgeContent={unreadMessages} color="info">
                        <span className="d-flex align-items-center">
                            <ChatBubbleIcon className="me-1" /> Berichten
                        </span>
                    </Badge>
                ),
                route: `/chat`,
            })
        }

        return result
    }, [userContext.user, unreadMessages, unreadNotifications])

    useEffect(() => {
        const pollUnreadMessages = async () => {
            if (userContext.user) {
                const resp = await UserService.unreadMessages()
                setUnreadMessages(resp)
            }
        }

        if (userContext.user) {
            pollUnreadMessages()
        }

        const intervalId = setInterval(pollUnreadMessages, 60000)
        return () => clearInterval(intervalId)
    }, [userContext.user])

    return (
        <MKBox
            style={{
                position: 'sticky',
                top: '0px',
                zIndex: '10',
            }}
            className="ck-header-container"
            variant="gradient"
            bgColor="light"
            shadow="sm"
        >
            <DefaultNavbar
                brand={
                    <div>
                        {darkMode.dark ? (
                            <img src={logoLight} width="100px" className="me-1" alt="Light logo" />
                        ) : (
                            <img src={logoDark} width="100px" className="me-1" alt="Dark logo" />
                        )}
                    </div>
                }
                action={
                    !userContext ||
                    (!userContext.user && {
                        type: 'internal',
                        route: '/login',
                        label: (
                            <>
                                <LoginIcon className="me-2" />
                                Log in
                            </>
                        ),
                        color: 'info',
                    })
                }
                routes={routes}
                light={false}
                relative={isMobile}
                transparent={false}
                darkModeToggle
            />
        </MKBox>
    )
}

export default CKNavbar
