import bgImage from 'assets/images/user_banner_default.jpg'
import { CKLoading } from 'components/Generic/CKLoading'
import { CKHero } from 'components/UI/CKHero'
import { UserIcon } from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent } from 'components/UI/CKPageContent'
import User from 'models/User'

export interface IUserPageContainer {
    user: User
    children: any
    loading: boolean
    adminPanel?: boolean
}

export const UserPageContainer = ({ user, loading, children, adminPanel }: IUserPageContainer) => {
    return (
        <>
            <CKHero
                size={adminPanel ? 'xxs' : 'md'}
                bgImage={user && user.$banner ? user.$bannerUrl : bgImage}
            />
            <CKPageContent>
                {loading ? (
                    <CKLoading />
                ) : (
                    <>
                        {user && user.$picture && user.$pictureUrl ? (
                            <CKPageBadge src={user.$pictureUrl}></CKPageBadge>
                        ) : (
                            <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                                <UserIcon fontSize="large" style={{ color: 'white' }} />\
                            </CKPageBadge>
                        )}
                        {children}
                    </>
                )}
            </CKPageContent>
        </>
    )
}
