import Social from './Social'

export default abstract class CKModel {
    protected id: string | undefined
    protected slug: string | undefined
    protected socials: Social[]
    protected name: string | undefined
    protected calculated_access: string[] | undefined
    protected created_at: string | undefined

    constructor(
        id: string | undefined = undefined,
        slug: string | undefined = undefined,
        socials: Social[] = []
    ) {
        this.id = id
        this.slug = slug
        this.socials = socials
    }

    public get $id(): string | undefined {
        return this.id
    }

    public get $slug(): string | undefined {
        return this.slug
    }
    public set $slug(value: string | undefined) {
        this.slug = value
    }

    public get $socials(): Social[] {
        return this.socials
    }
    public set $socials(value: Social[]) {
        this.socials = value
    }

    public get $name(): string | undefined {
        return this.name
    }
    public set $name(value: string | undefined) {
        this.name = value
    }

    public hasRight(right: string): boolean {
        if (!this.calculated_access) return false
        if (this.calculated_access.includes('FULL') || this.calculated_access.includes('OWNER'))
            return true
        return this.calculated_access.includes(right.toUpperCase())
    }

    public isOwner(): boolean {
        if (!this.calculated_access) return false
        return this.calculated_access.includes('OWNER')
    }

    public get $adminUrl(): string {
        return `/admin/${this.$className}/${this.slug}`
    }

    public get $detailUrl(): string {
        return `/${this.$className}/${this.slug}`
    }

    public abstract get $className(): string

    public abstract validate(): Record<string, any>

    public get $created_at(): Date | undefined {
        if (!this.created_at) return undefined
        return new Date(this.created_at)
    }
}
