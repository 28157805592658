import { Grid, Switch } from '@mui/material'
import CKDatePicker from 'components/Form/CKDatePicker'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import User from 'models/User'
import { FC, useState, useEffect } from 'react'

interface Props {
    user: User
    errors: Record<string, any>
}

const GeneralForm: FC<Props> = ({ user, errors }) => {
    const [isOpenMicer, setIsOpenMicer] = useState(user.$isOpenMicer)
    const [isMC, setIsMC] = useState(user.$isMC)
    const [isOrganizer, setIsOrganizer] = useState(user.$isOrganizer)

    useEffect(() => {
        setIsOpenMicer(user.$isOpenMicer)
        setIsMC(user.$isMC)
        setIsOrganizer(user.$isOrganizer)
    }, [user])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <MKInput
                    label="Voornaam"
                    error={errors['first_name'] ? true : false}
                    defaultValue={user.$first_name}
                    onChange={(e) => {
                        user.$first_name = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['first_name']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12} md={6}>
                <MKInput
                    label="Achternaam"
                    error={errors['last_name'] ? true : false}
                    defaultValue={user.$last_name}
                    onChange={(e) => {
                        user.$last_name = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['last_name']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12}>
                <MKInput
                    type="email"
                    label="Email"
                    error={errors['email'] ? true : false}
                    defaultValue={user.$email}
                    onChange={(e) => {
                        user.$email = e.target.value
                    }}
                    required
                    fullWidth
                />
                <CKFormFeedback>{errors['email']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12}>
                <MKInput
                    type="text"
                    label="Beschrijving"
                    defaultValue={user.$description}
                    onChange={(e) => (user.$description = e.target.value)}
                    fullWidth
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item xs={12}>
                <MKTypography variant="button" color="text" fontWeight="bold">
                    Duidt aan wat van toepassing is op jou:
                </MKTypography>
                <div className="d-flex justify-content-between align-items-center px-3">
                    <MKTypography variant="button" color="dark">
                        Open-Mic'er
                    </MKTypography>
                    <Switch
                        checked={isOpenMicer}
                        color="info"
                        onChange={(e) => {
                            setIsOpenMicer(e.target.checked)
                            user.$isOpenMicer = e.target.checked
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center px-3">
                    <MKTypography variant="button" color="dark">
                        MC
                    </MKTypography>
                    <Switch
                        checked={isMC}
                        color="info"
                        onChange={(e) => {
                            setIsMC(e.target.checked)
                            user.$isMC = e.target.checked
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center px-3">
                    <MKTypography variant="button" color="dark">
                        Organisator
                    </MKTypography>
                    <Switch
                        checked={isOrganizer}
                        color="info"
                        onChange={(e) => {
                            setIsOrganizer(e.target.checked)
                            user.$isOrganizer = e.target.checked
                        }}
                    />
                </div>
            </Grid>
            {(isOpenMicer || isMC) && (
                <Grid item xs={12}>
                    <CKDatePicker
                        label="Begonnen met comedy"
                        onChange={(date) => (user.$started_at = date)}
                        defaultValue={user.$started_at}
                        fullWidth={true}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default GeneralForm
