import { Grid } from '@mui/material'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useUserContext } from 'contexts/UserContext'
import Event from 'models/Event'
import { FC } from 'react'
import TimeUtils from 'utils/TimeUtils'

export interface IEventTimes {
    event: Event
    className?: string
}
export const EventTimes: FC<IEventTimes> = ({ event, className }) => {
    const userContext = useUserContext()

    if (!event) return null

    return (
        <div className={className}>
            <Grid container columnSpacing={3}>
                <Grid item xs={6} style={{ textAlign: 'right', lineHeight: '1rem' }}>
                    <MKTypography variant="button" color="text" style={{ fontWeight: 'bold' }}>
                        Datum:
                    </MKTypography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'left', lineHeight: '1rem' }}>
                    <MKTypography variant="button">
                        {TimeUtils.toDateString(event.$start_time, true)}
                    </MKTypography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', lineHeight: '1rem' }}>
                    <MKTypography variant="button" color="text" style={{ fontWeight: 'bold' }}>
                        Start:
                    </MKTypography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'left', lineHeight: '1rem' }}>
                    <MKTypography variant="button">
                        {TimeUtils.toTimeString(event.$start_time)}
                    </MKTypography>
                </Grid>
                {userContext && userContext.user && (
                    <>
                        <Grid item xs={6} style={{ textAlign: 'right', lineHeight: '1rem' }}>
                            <MKTypography
                                variant="button"
                                color="text"
                                style={{ fontWeight: 'bold' }}
                            >
                                Aankomst:
                            </MKTypography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'left', lineHeight: '1rem' }}>
                            <MKTypography variant="button">
                                {TimeUtils.toTimeString(event.$arrival_time)}
                            </MKTypography>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    )
}
