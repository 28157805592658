import CKDeleteModelButton from 'components/Buttons/CKDeleteModelButton'
import CKEditModelButton from 'components/Buttons/CKEditModelButton'
import { CKElevatedUsersMenuItem, CKElevatedUsersModal } from 'components/Buttons/CKElevatedUsers'
import CKSettingsButton from 'components/Buttons/CKSettingsButton'
import MKButton from 'components/MaterialKit/MKButton'
import { QrCodeScannerIcon, ReceiptIcon } from 'components/UI/CKIcons'
import { CKLineupEditMenuItem } from 'components/UI/CKLineupEdit'
import CKLineupEditModal from 'components/UI/CKLineupEdit/CKLineupEditModal'
import Event from 'models/Event'
import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIsMobile } from 'utils/ViewportUtils'

export interface IEventAdminActions {
    event: Event
    onShowSales?: () => void
}
export const AdminActions: FC<IEventAdminActions> = ({ event, onShowSales }) => {
    const [elevatedUsersOpen, setElevatedUsersOpen] = useState(false)
    const [lineupOpen, setLineupOpen] = useState(false)
    const history = useHistory()
    const isMobile = useIsMobile()

    if (!event) return null
    return (
        <div className={`d-flex p-3 gap-3 justify-content-center ${isMobile ? 'flex-column' : ''}`}>
            <div className="d-flex gap-3 align-items-center justify-content-center">
                <CKSettingsButton hideWhenEmpty className="ms-2">
                    {event.hasRight('FULL') && (
                        <CKEditModelButton className="h-100 w-100" model={event} menuItem />
                    )}
                    {event.hasRight('LINEUP') && (
                        <CKLineupEditMenuItem
                            onClick={() => {
                                setLineupOpen(true)
                            }}
                        />
                    )}
                    {event.isOwner() && (
                        <CKElevatedUsersMenuItem
                            onClick={() => {
                                setElevatedUsersOpen(true)
                            }}
                        />
                    )}
                    {event.isOwner() && (
                        <CKDeleteModelButton className="h-100 w-100" model={event} />
                    )}
                </CKSettingsButton>
                <CKElevatedUsersModal
                    model={event}
                    open={elevatedUsersOpen}
                    onClose={() => {
                        setElevatedUsersOpen(false)
                    }}
                />
                <CKLineupEditModal
                    event={event}
                    open={lineupOpen}
                    onClose={() => {
                        setLineupOpen(false)
                    }}
                />
            </div>
            <div className="d-flex gap-3">
                {/* <CKShareButtons /> */}
                {event.hasRight('FULL') && event.$tickets.length > 0 && (
                    <div className="d-flex justify-content-center">
                        <MKButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                                history.push(`/event/${event.$slug}/scanner`)
                            }}
                        >
                            <QrCodeScannerIcon className="me-2" />
                            Ticket scanner
                        </MKButton>
                    </div>
                )}
                {event.hasRight('FULL') && event.$tickets.length > 0 && (
                    <MKButton variant="outlined" color="info" size="small" onClick={onShowSales}>
                        <ReceiptIcon className="me-2" />
                        Verkochte tickets
                    </MKButton>
                )}
            </div>
        </div>
    )
}
