import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    MDXEditor,
    UndoRedo,
    headingsPlugin,
    listsPlugin,
    thematicBreakPlugin,
    toolbarPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import './style.scss'

interface MarkdownEditorProps {
    markdown: string
    onChange: (markdown: string) => void
}

export const CKMarkdownEditor = ({ markdown, onChange }: MarkdownEditorProps) => {
    return (
        <div data-testid="markdown-editor" className="ck-markdown-editor">
            <MDXEditor
                markdown={markdown}
                onChange={onChange}
                plugins={[
                    headingsPlugin({
                        allowedHeadingLevels: [1, 2, 3],
                    }),
                    thematicBreakPlugin(),
                    listsPlugin(),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                                <UndoRedo />
                                <BlockTypeSelect />
                                <BoldItalicUnderlineToggles />
                            </>
                        ),
                    }),
                ]}
            />
        </div>
    )
}
