import CKModel from './CKModel'
import User from './User'

export default class ChatMessage extends CKModel {
    private content: string | undefined
    private is_notification: boolean | undefined
    private user: User | undefined

    constructor() {
        super()
    }

    public static fromJSON(json: any): ChatMessage {
        let result = new ChatMessage()
        result = Object.assign(result, json)

        if (json.user) {
            result.$user = User.fromJSON(json.user)
        }

        return result
    }

    public get $user(): User | undefined {
        return this.user
    }
    public set $user(value: User | undefined) {
        this.user = value
    }

    public get $isNotification(): boolean | undefined {
        return this.is_notification
    }
    public set $isNotification(value: boolean | undefined) {
        this.is_notification = value
    }

    public get $content() {
        return this.content
    }
    public set $content(value: string | undefined) {
        this.content = value
    }

    public get $className(): string {
        return 'ChatMessage'
    }

    public validate(): Record<string, any> {
        const errors: Record<string, any> = {}

        if (!this.content) errors.content = 'Content is required'

        return errors
    }
}
