import Event from 'models/Event'
import PendingImage from 'models/PendingImage'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ImageService from 'services/ImageService'
import EventService, { useEvent } from './EventService'
import { Zoom } from 'react-toastify'
import { toast } from 'react-toastify'

export function useEventEdit(slug: string) {
    const [event, setEvent] = useState<Event | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<any>({})
    const [saving, setSaving] = useState<boolean>(false)
    const history = useHistory()
    const { mutate } = useEvent(slug)

    useEffect(() => {
        const loadEvent = async () => {
            if (!slug) return
            setLoading(true)
            try {
                if (slug === 'new') {
                    setEvent(new Event())
                } else {
                    const evt = await EventService.get(slug)
                    setEvent(evt)
                }
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }
        loadEvent()
    }, [slug])

    const validate = () => {
        if (!event) return false
        const _errors = event.validate()
        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        if (!event) return
        setSaving(true)
        try {
            if (!validate()) {
                toast.error('Gegevens incorrect', {
                    containerId: 'B',
                    transition: Zoom,
                    autoClose: 2000,
                })
                return
            }

            // Upload logo if it's a pending image
            if (event.$logo instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: event.$logo.$file,
                    name: `${event.$name} - LOGO`,
                    type: 'LOGO',
                })
                event.$logo = image
            }

            // Upload banner if it's a pending image
            if (event.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: event.$banner.$file,
                    name: `${event.$name} - BANNER`,
                    type: 'BANNER',
                })
                event.$banner = image
            }

            // Handle custom lineup images
            if (event.$custom_lineup) {
                for (const spot of event.$custom_lineup) {
                    await spot.uploadPendingImage()
                }
            }

            let savedEvent: Event
            if (slug === 'new') {
                savedEvent = await EventService.post(event)
            } else {
                savedEvent = await EventService.update(event)
            }
            toast.success('Opgeslagen', {
                containerId: 'B',
                transition: Zoom,
                autoClose: 2000,
            })
            history.push(savedEvent.$detailUrl)
            mutate()
        } catch (e) {
            console.error(e)
        } finally {
            setSaving(false)
        }
    }

    const cancel = () => {
        const dashboardUrl = '/dashboard?tab=events'
        if (slug === 'new') {
            history.push(dashboardUrl)
        } else {
            history.push(event?.$detailUrl || dashboardUrl)
        }
    }

    return {
        event,
        loading,
        saving,
        errors,
        save,
        cancel,
    }
}
