import { Grid } from '@mui/material'
import { Scanner } from '@yudiel/react-qr-scanner'
import MKAlert from 'components/MaterialKit/MKAlert'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CheckIcon, ErrorIcon, PresenceIcon } from 'components/UI/CKIcons'
import { CKPageTitle } from 'components/UI/CKPageContent'
import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useEvent, useEventTicketStatistics } from 'services/Event/EventService'
import { scanTicket } from 'services/TicketService'
import { EventPageContainer } from '../EventPageContainer'
import './style.scss'

interface IScanStatus {
    status: 'SUCCESS' | 'ERROR'
    message: string
}
export const PageEventTicketScanner: FC = () => {
    const eventSlug = useParams()['eventSlug']
    const { event, isLoading: loading } = useEvent(eventSlug)
    const [scanStatus, setScanStatus] = React.useState<IScanStatus | null>(null)
    const [lastScanTime, setLastScanTime] = React.useState<number>(0)

    const history = useHistory()
    if (!loading && event && !event.hasRight('FULL')) {
        history.push(`/event/${event.$slug}`)
    }

    const TicketScanInfo = () => {
        const { ticketScanStatistics } = useEventTicketStatistics(event)

        return (
            <>
                <MKTypography fontWeight="bold" variant="label" color="info">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ fontSize: '1rem' }}
                    >
                        <PresenceIcon className="me-2" />
                        Aanwezigheden
                    </div>
                </MKTypography>
                <Grid container columnSpacing={3}>
                    {event.$tickets.map((ticket) => (
                        <React.Fragment key={`ticket-sold-${ticket.$id}`}>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <MKTypography
                                    variant="button"
                                    color="text"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {ticket.$title}
                                </MKTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <MKTypography variant="button" color="text">
                                    {ticketScanStatistics[ticket.$id] ?? ticket.$amountScanned} /{' '}
                                    {ticket.$amount}
                                </MKTypography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </>
        )
    }

    const handleTicketScan = async (ticketUuid: string) => {
        const currentTime = Date.now()
        const cooldownPeriod = 3000 // 1 second in milliseconds

        if (currentTime - lastScanTime < cooldownPeriod) {
            return // Skip if not enough time has passed
        }

        setLastScanTime(currentTime)

        try {
            setScanStatus(null)
            await scanTicket(event, ticketUuid)
            setScanStatus({ status: 'SUCCESS', message: 'Helemaal mooi!' })
            setTimeout(() => {
                setScanStatus(null)
            }, 3000)
        } catch (error) {
            setScanStatus({ status: 'ERROR', message: error.message })
            setTimeout(() => {
                setScanStatus(null)
            }, 5000)
        }
    }

    return (
        <EventPageContainer event={event} loading={loading}>
            {event && (
                <>
                    <CKPageTitle>{event.$name}</CKPageTitle>
                    <div className="d-flex justify-content-center py-3">
                        <div style={{ maxWidth: '500px', width: '100%' }}>
                            {scanStatus && (
                                <MKAlert
                                    color={scanStatus.status.toLowerCase()}
                                    dismissible
                                    className="scan-alert"
                                >
                                    {scanStatus.status === 'ERROR' ? (
                                        <ErrorIcon className="me-2" />
                                    ) : (
                                        <CheckIcon className="me-2" />
                                    )}
                                    {scanStatus.message}
                                </MKAlert>
                            )}
                            <Scanner
                                onResult={(text, result) => handleTicketScan(text)}
                                onError={(error) => console.log(error?.message)}
                            />
                        </div>
                    </div>

                    <TicketScanInfo />
                </>
            )}
        </EventPageContainer>
    )
}
