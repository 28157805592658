import { useTheme } from '@emotion/react'
import { isPlatform } from '@ionic/react'
import DirectionsIcon from '@mui/icons-material/Directions'
import { Grid } from '@mui/material'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import bgImage from 'assets/images/crowd_laughing.png'
import clsx from 'clsx'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import Location from 'models/Location'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

interface Props {
    location: Location
    hideNavigateButton?: boolean
}
const CKLocationDetailCard: FC<Props> = (props) => {
    const theme = useTheme()
    const history = useHistory()

    const center = useMemo(() => {
        if (!props.location) return { lat: 0, lng: 0 }
        return {
            lat: props.location.$address.$lat,
            lng: props.location.$address.$lng,
        }
    }, [props.location])

    if (!props.location) return null
    return (
        <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            sx={{ overflow: 'hidden' }}
            my={6}
        >
            <MKBox
                component="img"
                src={props.location.$banner ? props.location.$bannerUrl : bgImage}
                // objectFit="cover"
                alt="pattern-lines"
                position="absolute"
                top={0}
                left={0}
                width={{ xs: 'auto', md: '100%' }}
                height={{ xs: '100%', md: 'auto' }}
                zIndex={0}
                className="ck-location-banner"
                style={{
                    maskImage: 'linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0,0,0,0.4))',
                }}
            />
            <Grid container className="p-4">
                <Grid
                    item
                    xs={12}
                    md={4}
                    className="d-flex align-items-center justify-content-center flex-column"
                >
                    <div
                        className={clsx({
                            'ck-address-btn': props.location.$detailUrl,
                        })}
                        style={{ zIndex: 1 }}
                        onClick={(e) => {
                            if (props.location.$detailUrl) {
                                history.push(props.location.$detailUrl)
                            }
                        }}
                    >
                        <img
                            src={props.location.$logoUrl}
                            height={'100px'}
                            className="mb-3"
                            // style={{ mixBlendMode: 'multiply' }}
                        />
                        <div className={'text-center'}>
                            <div className="mb-3">
                                <MKTypography variant="label" fontWeight="bold" color="info">
                                    {props.location.$name}
                                </MKTypography>
                                <br></br>
                                <MKTypography variant="button" color="light" fontWeight="bold">
                                    {props.location.$address.$street}{' '}
                                    {props.location.$address.$streetNumber}
                                </MKTypography>
                                <br></br>
                                <MKTypography variant="button" color="light" fontWeight="bold">
                                    {props.location.$address.$postalCode}{' '}
                                    {props.location.$address.$city}
                                </MKTypography>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    {!props.hideNavigateButton && (
                        <div
                            className={
                                'text-center ' + props.location.$detailUrl && 'ck-address-btn'
                            }
                        >
                            <MKButton
                                variant="gradient"
                                color="info"
                                className="mt-4 mb-2"
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (isPlatform('ios')) {
                                        window.open(
                                            `maps://maps.apple.com/q=${props.location.$address.$lat},${props.location.$address.$lng}`
                                        )
                                    } else if (isPlatform('android')) {
                                        window.open(
                                            `geo:0,0?q=${props.location.$address.$lat},${props.location.$address.$lng}`,
                                            '_system'
                                        )
                                    } else {
                                        window.open(
                                            `https://www.google.com/maps/dir//${props.location.$name}/@${props.location.$address.$lat},${props.location.$address.$lng},17z`
                                        )
                                    }
                                }}
                            >
                                <DirectionsIcon className="me-2" /> Navigeer
                            </MKButton>
                        </div>
                    )}
                </Grid>
                <Grid item xs={0} md={8}>
                    {props.location && (
                        <MKBox height={300}>
                            <GoogleMap
                                zoom={20}
                                center={{
                                    lat: props.location.$address.$lat,
                                    lng: props.location.$address.$lng,
                                }}
                                mapContainerClassName="map-container"
                            >
                                {center && <MarkerF position={center} />}
                            </GoogleMap>
                        </MKBox>
                    )}
                </Grid>
            </Grid>
        </MKBox>
    )
}
export default CKLocationDetailCard
