import { Container, Grid, useMediaQuery } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC, ReactNode } from 'react'
import bgImage1 from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'

interface Props {
    bgImage?: string
    title?: string | ReactNode
    className?: any
    maxHeight?: string
    height?: string
    description?: string
    size?: 'lg' | 'md' | 'sm' | 'xs' | 'xxs'
}

const CKHero: FC<Props> = ({bgImage: propImage, ...props}) => {
    const size = props.size || 'lg'
    const isMobile = useMediaQuery('(max-width:768px)')
    const bgImage = propImage ? propImage: isMobile ? bgImage2 : bgImage1

    const sizes = isMobile
        ? {
              lg: '25rem',
              md: '20rem',
              sm: '15rem',
              xs: '10rem',
              xxs: '10rem',
          }
        : {
              lg: '35rem',
              md: '30rem',
              sm: '25rem',
              xs: '20rem',
              xxs: '15rem',
          }

    return (
        <MKBox
            className={props.className}
            minHeight={sizes[size]}
            maxHeight={props.maxHeight}
            height={props.height}
            width="100%"
            sx={{
                backgroundImage: ({
                    functions: { linearGradient, rgba },
                    palette: { gradients },
                }) =>
                    `${linearGradient(
                        rgba(gradients.dark.main, 0.6),
                        rgba(gradients.dark.state, 0.6)
                    )}, url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'grid',
                placeItems: 'center',
                maxHeight: props.maxHeight,
            }}
        >
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ mx: 'auto', textAlign: 'center' }}
                >
                    <MKTypography
                        variant="h1"
                        color="white"
                        sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down('md')]: {
                                fontSize: size['3xl'],
                            },
                        })}
                    >
                        {props.title}
                    </MKTypography>
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                        {props.description}
                    </MKTypography>
                </Grid>
            </Container>
        </MKBox>
    )
}
export default CKHero
