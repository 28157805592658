import CKModel from './CKModel'

export default class BugReport extends CKModel {
    private first_name: string
    private last_name: string
    private short_description: string
    private description: string
    private in_progress: boolean
    private score: number
    private own_vote: number

    public static fromJSON(json: any): BugReport {
        let result = new BugReport()
        result = Object.assign(result, json)
        return result
    }

    public get $firstName(): string {
        return this.first_name
    }
    public set $firstName(value: string) {
        this.first_name = value
    }

    public get $lastName(): string {
        return this.last_name
    }
    public set $lastName(value: string) {
        this.last_name = value
    }

    public get $shortDescription(): string {
        return this.short_description
    }
    public set $shortDescription(value: string) {
        this.short_description = value
    }

    public get $description(): string {
        return this.description
    }
    public set $description(value: string) {
        this.description = value
    }

    public get $inProgress(): boolean {
        return this.in_progress
    }
    public set $inProgress(value: boolean) {
        this.in_progress = value
    }

    public get $score(): number {
        return this.score
    }
    public set $score(value: number) {
        this.score = value
    }

    public get $ownVote(): number {
        return this.own_vote
    }
    public set $ownVote(value: number) {
        this.own_vote = value
    }

    public get $className(): string {
        return 'bug-report'
    }

    public validate(): Record<string, any> {
        const errors: Record<string, any> = {}

        if (!this.first_name) errors.first_name = 'First name is required'
        if (!this.last_name) errors.last_name = 'Last name is required'
        if (!this.short_description) errors.short_description = 'Short description is required'
        if (!this.description) errors.description = 'Description is required'

        return errors
    }
}
