import MKButton from 'components/MaterialKit/MKButton'
import { SaveIcon } from 'components/UI/CKIcons'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { Grid } from '@mui/material'

interface FooterProps {
    onSave?: () => void
    onCancel?: () => void
    saving?: boolean
    saveText?: string
    cancelText?: string
}

export const Footer: FC<FooterProps> = ({
    onSave,
    onCancel,
    saving = false,
    saveText = 'Opslaan',
    cancelText = 'Annuleer',
}) => {
    const history = useHistory()

    // If no save or cancel, return null
    if (!onSave && !onCancel) return null

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        } else {
            history.push('/')
        }
    }

    return (
        <div className="text-center mt-3 mx-1">
            <Grid 
                container 
                sx={{
                    '@media (min-width: 1200px)': {
                        justifyContent: 'space-between',
                        maxWidth: '500px',
                        margin: '0 auto'
                    },
                    '@media (min-width: 768px) and (max-width: 1200px)': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1rem',  // Added vertical gap
                        '& .MKButton': {
                            width: '130px'
                        }
                    },
                    '@media (max-width: 768px)': {
                        flexDirection: 'row',
                        justifyContent: 'space-evenly'
                    }
                }}
            >
                {onCancel && (
                    <Grid item>
                        <MKButton
                            variant="gradient"
                            color="secondary"
                            onClick={handleCancel}
                            disabled={saving}
                        >
                            {cancelText}
                        </MKButton>
                    </Grid>
                )}
                {onSave && (
                    <Grid item>
                        <MKButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            onClick={onSave}
                            disabled={saving}
                        >
                            {saving ? (
                                <span className="mb-0 me-2">
                                    <ClipLoader size={15} color={'white'} />
                                </span>
                            ) : (
                                <SaveIcon className="me-2" />
                            )}
                            {saveText}
                        </MKButton>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
