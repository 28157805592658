import { DEFAULT_PROFILE_BANNER, DEFAULT_PROFILE_PICTURE } from 'constants/images'
import CKModel from './CKModel'
import ComedyTag from './ComedyTag'
import EventSpot from './EventSpot'
import Image from './Image'
import PendingImage from './PendingImage'
import Social from './Social'
import { Banner, Picture } from './interfaces/Images'

export default class User extends CKModel implements Picture, Banner {
    private first_name: string
    private last_name: string
    private birthdate: Date | null
    private email: string
    private picture: Image | PendingImage | null
    private banner: Image | PendingImage | null
    private started_at: Date | null
    private description: string
    private comedy_tags: ComedyTag[]
    private applications: EventSpot[] | undefined
    public pivot: any | undefined
    private amount_unread_messages: number | undefined
    private preferences: any | null
    private mc: boolean | undefined
    private openmicer: boolean | undefined
    private organizer: boolean | undefined

    private picture_id: string | null
    private banner_id: string | null
    constructor(
        id: string | null = null,
        slug: string | undefined = undefined,
        first_name: string = '',
        last_name: string = '',
        birthdate: Date | null = null,
        email: string = '',
        picture: Image | null = null,
        banner: Image | null = null,
        started_at: Date | null = null,
        description: string = '',
        comedy_tags: ComedyTag[] = [],
        banner_id: string | null = null,
        picture_id: string | null = null,
        applications: EventSpot[] | undefined = undefined,
        preferences: any | null = null,
        socials: Social[] = []
    ) {
        super(id, slug, socials)

        this.first_name = first_name
        this.last_name = last_name
        this.birthdate = birthdate
        this.email = email
        this.picture = picture
        this.banner = banner
        this.started_at = started_at
        this.description = description
        this.comedy_tags = comedy_tags

        this.picture_id = picture_id
        this.banner_id = banner_id
        this.applications = applications
        this.preferences = preferences
    }

    public static fromJSON(json: any): User {
        const user: User = new User()
        Object.assign(user, json)

        if (json.started_at) {
            user.started_at = new Date(json.started_at)
        }

        if (json.picture) {
            user.picture = Image.fromJSON(json.picture)
        }

        if (json.banner) {
            user.banner = Image.fromJSON(json.banner)
        }

        user.comedy_tags = []
        if (json.comedy_tags) {
            json.comedy_tags.forEach((comedyTag) => {
                user.comedy_tags.push(ComedyTag.fromJSON(comedyTag))
            })
        }

        if (json.applications) {
            user.$applications = []
            for (const app of json.applications) {
                user.$applications.push(EventSpot.fromJSON(app))
            }
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            user.$socials = socials
        }

        return user
    }

    public get $name(): string {
        return this.first_name + ' ' + this.last_name
    }

    public get $first_name(): string {
        return this.first_name
    }
    public set $first_name(value: string) {
        this.first_name = value
    }

    public get $last_name(): string {
        return this.last_name
    }
    public set $last_name(value: string) {
        this.last_name = value
    }

    public get $birthdate(): Date | null {
        return this.birthdate
    }
    public set $birthdate(value: Date | null) {
        this.birthdate = value
    }

    public get $email(): string {
        return this.email
    }
    public set $email(value: string) {
        this.email = value
    }

    /**
     * Picture
     */
    public get $picture(): Image | PendingImage | null {
        return this.picture
    }
    public set $picture(value: Image | PendingImage | null) {
        this.picture = value
        if (value instanceof Image) {
            this.picture_id = value.$id
        } else {
            this.picture_id = null
        }
    }
    public get $pictureUrl(): string {
        if (this.picture instanceof Image) {
            return this.picture.$url
        }
        if (this.picture instanceof PendingImage) {
            return this.picture.$previewUrl
        }
        return DEFAULT_PROFILE_PICTURE
    }

    /**
     * Banner
     */
    public get $banner(): Image | PendingImage | null {
        return this.banner
    }
    public set $banner(value: Image | PendingImage | null) {
        this.banner = value
        if (value instanceof Image) {
            this.banner_id = value.$id
        } else {
            this.banner_id = null
        }
    }
    public get $bannerUrl(): string {
        if (this.$banner instanceof Image) {
            return this.$banner.$url
        }
        if (this.$banner instanceof PendingImage) {
            return this.$banner.$previewUrl
        }
        return DEFAULT_PROFILE_BANNER
    }

    public get $started_at(): Date | null {
        return this.started_at
    }
    public set $started_at(value: Date | null) {
        this.started_at = value
    }

    public get $description(): string {
        return this.description
    }
    public set $description(value: string) {
        this.description = value
    }

    public get $comedy_tags(): ComedyTag[] {
        return this.comedy_tags
    }
    public set $comedy_tags(value: ComedyTag[]) {
        this.comedy_tags = value
    }

    public get $applications(): EventSpot[] | undefined {
        return this.applications
    }
    public set $applications(value: EventSpot[] | undefined) {
        this.applications = value
    }

    public get $amountUnreadMessages(): number | undefined {
        return this.amount_unread_messages
    }
    public set $amountUnreadMessages(value: number | undefined) {
        this.amount_unread_messages = value
    }

    public get $preferences(): any | null {
        return this.preferences
    }
    public set $preferences(value: any | null) {
        this.preferences = value
    }

    public get $className(): string {
        return 'user'
    }

    public get $isMC(): boolean | undefined {
        return this.mc
    }
    public set $isMC(value: boolean | undefined) {
        this.mc = value
    }

    public get $isOpenMicer(): boolean | undefined {
        return this.openmicer
    }
    public set $isOpenMicer(value: boolean | undefined) {
        this.openmicer = value
    }

    public get $isOrganizer(): boolean | undefined {
        return Boolean(this.organizer)
    }
    public set $isOrganizer(value: boolean | undefined) {
        this.organizer = value
    }

    public validate(): Record<string, any> {
        const errors: Record<string, any> = {}
        const defaultMsg = 'Verplicht veld!'

        if (!this.first_name) errors.first_name = defaultMsg
        if (!this.last_name) errors.last_name = defaultMsg
        if (!this.email) errors.email = defaultMsg

        return errors
    }
}
