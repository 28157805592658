import { Container, TableBody, TableCell, TableRow } from '@mui/material'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { CKLoading } from 'components/Generic/CKLoading'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ArrowBackIcon, EditIcon } from 'components/UI/CKIcons'
import CKTable from 'components/UI/CKTable/CKTable'
import CKTableHead from 'components/UI/CKTable/CKTableHead'
import Event from 'models/Event'
import { FC, useState } from 'react'
import { changeTicketEmail, useTicketSalesOverview } from 'services/TicketService'
import { isValidEmail } from 'utils/EmailUtils'
import ClipLoader from 'react-spinners/ClipLoader'

interface Props {
    event: Event
    onBack: () => void
}

export const TicketSalesOverview: FC<Props> = ({ event, onBack }) => {
    const { sales, isLoading, mutate } = useTicketSalesOverview(event)
    const [expandedRow, setExpandedRow] = useState<string | null>(null)
    const [email, setEmail] = useState('')
    const [verifyEmail, setVerifyEmail] = useState('')
    const [saving, setSaving] = useState(false)

    const handleChangeEmail = async (oldEmail: string) => {
        if (email !== verifyEmail) return
        setSaving(true)
        await changeTicketEmail(event, oldEmail, email)
        setExpandedRow(null)
        setEmail('')
        setVerifyEmail('')
        setSaving(false)
        await mutate()
    }

    const isEmailMismatch = email && verifyEmail && email !== verifyEmail
    const isEmailValid = !email || isValidEmail(email)
    const isVerifyEmailValid = !verifyEmail || isValidEmail(verifyEmail)

    if (isLoading) return <CKLoading />

    return (
        <Container>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <MKButton
                    variant="outlined"
                    color="info"
                    onClick={onBack}
                    size="small"
                    sx={{ minWidth: 'auto' }}
                >
                    <ArrowBackIcon className="me-1" fontSize="small" />
                    Terug
                </MKButton>
                <div>
                    <MKTypography variant="h4" sx={{ textAlign: 'right', ml: 2 }}>
                        Verkochte Tickets
                    </MKTypography>
                    <MKTypography variant="h6" color="text" sx={{ textAlign: 'right', ml: 2 }}>
                        {`Totaal gescand: ${event.$tickets
                            .map((ticket) => ticket.$amountScanned)
                            .reduce(
                                (a, b) => a + b,
                                0
                            )} / ${event.$tickets.map((ticket) => ticket.$amountSold).reduce((a, b) => a + b, 0)}`}
                    </MKTypography>
                </div>
            </div>

            <CKTable>
                <CKTableHead>
                    <TableCell></TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Gescand</TableCell>
                </CKTableHead>
                <TableBody>
                    {sales.map((sale) => {
                        const rowId = `sale-${sale.ticket_type}-${sale.user_email}`
                        return (
                            <>
                                <TableRow key={rowId}>
                                    <TableCell style={{ width: '48px' }}>
                                        <MKButton
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            sx={{ minWidth: 'auto', p: 1 }}
                                            onClick={() =>
                                                setExpandedRow(expandedRow === rowId ? null : rowId)
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </MKButton>
                                    </TableCell>
                                    <TableCell>{sale.user_email}</TableCell>
                                    <TableCell align="right">
                                        {sale.scanned_tickets}/{sale.total_tickets}
                                    </TableCell>
                                </TableRow>
                                {expandedRow === rowId && (
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <div className="d-flex flex-column gap-2 py-2">
                                                <MKBox mb={2}>
                                                    <MKInput
                                                        type="email"
                                                        label="Email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        fullWidth
                                                        error={!isEmailValid || isEmailMismatch}
                                                        disabled={saving}
                                                    />
                                                    {!isEmailValid && (
                                                        <CKFormFeedback>Voer een geldig e-mailadres in</CKFormFeedback>
                                                    )}
                                                    {isEmailMismatch && (
                                                        <CKFormFeedback>De e-mailadressen komen niet overeen</CKFormFeedback>
                                                    )}
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput
                                                        type="email"
                                                        label="Bevestig email"
                                                        value={verifyEmail}
                                                        onChange={(e) => setVerifyEmail(e.target.value)}
                                                        fullWidth
                                                        error={!isVerifyEmailValid || isEmailMismatch}
                                                        disabled={saving}
                                                    />
                                                    {!isVerifyEmailValid && (
                                                        <CKFormFeedback>Voer een geldig e-mailadres in</CKFormFeedback>
                                                    )}
                                                    {isEmailMismatch && (
                                                        <CKFormFeedback>De e-mailadressen komen niet overeen</CKFormFeedback>
                                                    )}
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKButton
                                                        variant="gradient"
                                                        color="info"
                                                        onClick={() => handleChangeEmail(sale.user_email)}
                                                        disabled={
                                                            !email || 
                                                            !verifyEmail || 
                                                            email !== verifyEmail || 
                                                            !isEmailValid || 
                                                            !isVerifyEmailValid ||
                                                            saving
                                                        }
                                                    >
                                                        {saving ? <ClipLoader size={15} color="white" /> : 'Verander & verstuur tickets'}
                                                    </MKButton>
                                                </MKBox>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )
                    })}
                </TableBody>
            </CKTable>
        </Container>
    )
}
