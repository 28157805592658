import { TabSections } from './types'

export const processTabSections = (sections: TabSections, errors: Record<string, any>) => {
    return Object.entries(sections).reduce((acc, [key, section]) => {
        // Check if this is a root-style section (edit pages) or group-style section (dashboard)
        const isRootSection = key === 'root'
        
        if (isRootSection) {
            acc[key] = Object.entries(section).reduce((sectionAcc, [tabKey, tab]) => {
                sectionAcc[tabKey] = {
                    ...tab,
                    hasErrors: tab.errorFields?.some((field) => errors[field]) || false,
                }
                return sectionAcc
            }, {})
        } else {
            // For dashboard-style sections, just pass through without error processing
            acc[key] = section
        }
        return acc
    }, {} as TabSections)
}

export const findFirstErrorTab = (sections: TabSections): string | null => {
    if (!sections.root) return null

    const tabs = sections.root
    for (const [key, tab] of Object.entries(tabs)) {
        if (tab.hasErrors) {
            return key
        }
    }
    return null
} 