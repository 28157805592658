import { Container } from '@mui/material'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ImageIcon, InfoIcon, ShareIcon } from 'components/UI/CKIcons'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useLocationEdit } from 'services/Location/UseLocationEdit'
import { LocationPageContainer } from '../LocationPageContainer'
import GeneralForm from './GeneralForm'
import LocationImageForm from './LocationImageForm'

interface Props {}

const PageLocationEdit: FC<Props> = () => {
    const { locationId } = useParams<{ locationId: string }>()
    const { location, loading, saving, save, errors, cancel } = useLocationEdit(locationId)

    const sidebarTabs = {
        root: {
            general: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm location={location} errors={errors} />,
                errorFields: ['name', 'description', 'location'],
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: location && <LocationImageForm location={location} />,
                errorFields: ['logo', 'banner'],
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={location} />,
                errorFields: ['socials'],
            },
        },
    }

    return (
        <LocationPageContainer location={location} loading={loading} adminPanel={true}>
            <CKPageTitle>
                {locationId === 'new' ? 'Creëer een locatie' : 'Pas locatie aan'}
            </CKPageTitle>
            <MKTypography variant="caption" className="text-center">
                Hoppa, nog een plaats erbij om te lachen. Vul de gegevens van jouw locatie hier in
                en zorg ervoor dat je meute aan comedians je optijd kunnen vinden!
            </MKTypography>
            <Container className="my-3">
                <CKSidebarTabs
                    sections={sidebarTabs}
                    errors={errors}
                    hideGroup
                    saving={saving}
                    onSave={save}
                    onCancel={cancel}
                />
            </Container>
        </LocationPageContainer>
    )
}

export default PageLocationEdit
