import HandshakeIcon from '@mui/icons-material/Handshake'
import { Grid } from '@mui/material'
import logoComedyBooking from 'assets/images/logos/comedybooking-logo.png'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useDarkMode } from 'contexts/DarkModeContext'
import { FC } from 'react'
import { BRAND } from '../constants'

export const LogoColumn: FC = () => {
    const { dark } = useDarkMode()
    
    return (
        <Grid item xs={12} md={4} className="d-flex flex-column align-items-center">
            <MKBox
                component="img"
                src={dark ? BRAND.image : BRAND.image_dark}
                alt={BRAND.name}
                maxWidth="150px"
            />
            <MKTypography variant="button" component="div">
                <HandshakeIcon color="info" fontSize="medium" />
            </MKTypography>
            <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <a href="https://www.comedybooking.be" target="_blank">
                    <MKBox
                        component="img"
                        src={logoComedyBooking}
                        alt={BRAND.name}
                        width="100%"
                        maxWidth={150}
                    />
                </a>
            </MKBox>
        </Grid>
    )
} 