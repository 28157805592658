import { useMqtt } from 'contexts/MqttContext'
import Event from 'models/Event'
import User from 'models/User'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import AuthService, { ckFetcher, useSWRPaginated } from '../AuthService'
import { EVENTS } from '../Constants'

export function useEvents(
    page: number = 1,
    perPage: number = 10,
    search: string = '',
    locationSearch: { lat?: number; lng?: number; radius?: number } = undefined,
    lineupFilters: { lineupComplete?: boolean; spotsAvailable?: boolean } = undefined
) {
    let url = `${EVENTS}?page=${page}&per_page=${perPage}`
    if (search) {
        url += `&search=${search}`
    }
    if (locationSearch && locationSearch.lat && locationSearch.lng && locationSearch.radius) {
        url += `&location=${locationSearch.lat},${locationSearch.lng},${locationSearch.radius}`
    }

    if (lineupFilters) {
        let lineupFilterValue = 'all'
        if (lineupFilters.spotsAvailable && !lineupFilters.lineupComplete) {
            lineupFilterValue = 'spots_available'
        } else if (!lineupFilters.spotsAvailable && lineupFilters.lineupComplete) {
            lineupFilterValue = 'complete'
        }

        url += `&lineup=${lineupFilterValue}`
    }

    const { data, ...props } = useSWRPaginated(url)

    const _events: Event[] = []
    if (data)
        for (const e of data) {
            _events.push(Event.fromJSON(e))
        }

    return {
        events: _events,
        ...props,
    }
}

export function useEvent(slug: string) {
    const { data, ...props } = useSWR(`${EVENTS}/${slug}`, ckFetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    if (!data) return { event: null, ...props }

    const event = Event.fromJSON(data)
    return {
        event,
        ...props,
    }
}

export function useEventTicketStatistics(event: Event) {
    const mqtt = useMqtt()

    const [amountScanned, setAmountScanned] = useState<{ [key: string]: number }>({})
    useEffect(() => {
        const _amountScanned: { [key: string]: number } = {}
        for (const ticket of event.$tickets) {
            _amountScanned[ticket.$id] = ticket.$amountScanned
        }
        setAmountScanned(_amountScanned)
    }, [event, event.$tickets])

    if (event)
        mqtt.subscribeTo(`event/${event.$slug}/ticket_statistics`, (msg) => {
            console.log('msg', msg)
            setAmountScanned(msg)
        })

    const memoizedValue = useMemo(() => {
        return { ticketScanStatistics: amountScanned }
    }, [amountScanned])
    return memoizedValue
}

const EventService = {
    get: async function (slug: string) {
        const resp = await AuthService.get(`${EVENTS}/${slug}`)

        const event = Event.fromJSON(resp.data)
        return event
    },
    update: async function (event: Event) {
        console.log('event', event)
        const resp = await AuthService.put(`${EVENTS}/${event.$slug}`, event)

        const e = Event.fromJSON(resp.data)
        return e
    },
    post: async function (event: Event) {
        const resp = await AuthService.post(`${EVENTS}`, event)

        const e = Event.fromJSON(resp.data.data)
        return e
    },
    index: async function (
        page: number = 1,
        perPage: number = 10,
        search: string = '',
        location: { lat?: number; lng?: number; radius?: number } = undefined
    ) {
        const resp = await AuthService.get(EVENTS, {
            per_page: perPage,
            page: page,
            search: search,
            location:
                Boolean(location && location.lat && location.lng && location.radius) &&
                `${location.lat},${location.lng},${location.radius}`,
        })
        const result: Event[] = []

        for (const e of resp.data.data) {
            result.push(Event.fromJSON(e))
        }

        resp.data.data = result
        return resp.data
    },
    getApplications: async function (
        event: Event,
        page: number = 1,
        search: string = '',
        perPage: number = 50
    ) {
        const resp = await AuthService.get(
            `${EVENTS}/${event.$slug}/applications?per_page=${perPage}&page=${page}&search=${search}`
        )
        const result: User[] = []

        for (const e of resp.data) {
            result.push(User.fromJSON(e))
        }

        resp.data = result
        return resp.data
    },
    saveLineup: async function (
        event: Event,
        isComplete: boolean,
        data: { event_spot_id: number; user_id: number }[]
    ) {
        const resp = await AuthService.put(`${EVENTS}/${event.$slug}/lineup`, {
            is_complete: isComplete,
            line_up: data,
        })
        return resp.data
    },
}
export default EventService
