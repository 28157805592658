import { Container } from '@mui/material'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import { ImageIcon, InfoIcon, ShareIcon } from 'components/UI/CKIcons'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { TabSections } from 'components/UI/CKSidebarTabs/types'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useArtistEdit } from 'services/Artist/UseArtistEdit'
import { UserPageContainer } from '../UserPageContainer'
import GeneralForm from './GeneralForm'
import UserImageForm from './UserImageForm'

const PageUserEdit: FC = () => {
    const { userId } = useParams<{ userId: string }>()
    const { user, loading, saving, save, cancel, errors } = useArtistEdit(userId)

    const sidebarTabs: TabSections = {
        root: {
            General: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm user={user} errors={errors} />,
                errorFields: ['first_name', 'last_name', 'email', 'birthdate', 'description'],
            },
            Image: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: user && <UserImageForm user={user} />,
                errorFields: ['picture', 'banner'],
            },
            Socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={user} />,
                errorFields: ['socials'],
            },
        },
    }

    return (
        <UserPageContainer user={user} loading={loading} adminPanel>
            <CKPageTitle>{'Pas profiel aan'}</CKPageTitle>
            <Container className="my-3">
                {user && (
                    <CKSidebarTabs
                        sections={sidebarTabs}
                        errors={errors}
                        saving={saving}
                        onSave={save}
                        onCancel={cancel}
                        hideGroup
                    />
                )}
            </Container>
        </UserPageContainer>
    )
}

export default PageUserEdit
