import { DEFAULT_ORGANISATION_BANNER, DEFAULT_ORGANISATION_LOGO } from 'constants/images'
import CKModel from './CKModel'
import Image from './Image'
import PendingImage from './PendingImage'
import Social from './Social'
import { Banner, Logo } from './interfaces/Images'
import { EditableImage } from './types/ImageTypes'

interface OrganisationProps {
    id?: string
    slug?: string
    name?: string
    description?: string
    logo?: Image
    banner?: Image
    socials?: Social[]
}

export default class Organisation extends CKModel implements Logo, Banner {
    private description: string | undefined
    private logo: EditableImage
    private banner: EditableImage
    private mollie_connected: boolean | undefined

    private banner_id: string | undefined
    private logo_id: string | undefined

    constructor({
        id,
        slug,
        name,
        description,
        logo,
        banner,
        socials = [],
    }: OrganisationProps = {}) {
        super(id, slug, socials)

        this.name = name
        this.description = description
        this.logo = logo
        this.banner = banner
    }

    public static fromJSON(json: any) {
        let result = new Organisation()
        result = Object.assign(result, json)

        if (json.logo) {
            result.$logo = Image.fromJSON(json.logo)
        }

        if (json.banner) {
            result.$banner = Image.fromJSON(json.banner)
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            result.$socials = socials
        }

        return result
    }

    /**
     * Description
     */
    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    /**
     * Logo
     */
    public get $logo(): EditableImage {
        return this.logo
    }
    public set $logo(value: EditableImage) {
        this.logo = value
        if (value instanceof Image) {
            this.logo_id = value.$id
        } else {
            this.logo_id = undefined
        }
    }
    public get $logoUrl(): string | undefined {
        if (this.$logo instanceof Image) return this.$logo.$url
        if (this.$logo instanceof PendingImage) return this.$logo.$previewUrl
        return DEFAULT_ORGANISATION_LOGO
    }

    /**
     * Banner
     */
    public get $banner(): EditableImage {
        return this.banner
    }
    public set $banner(value: EditableImage) {
        this.banner = value
        if (value instanceof Image) {
            this.banner_id = value.$id
        } else {
            this.banner_id = undefined
        }
    }
    public get $bannerUrl(): string | undefined {
        if (this.$banner instanceof Image) return this.$banner.$url
        if (this.$banner instanceof PendingImage) return this.$banner.$previewUrl
        return DEFAULT_ORGANISATION_BANNER
    }

    public get $className(): string {
        return 'organisation'
    }

    /**
     * Mollie
     */
    public get $mollieConnected(): boolean {
        return Boolean(this.mollie_connected)
    }
    public set $mollieConnected(value: boolean) {
        this.mollie_connected = value
    }

    public validate(): Record<string, any> {
        const errors: Record<string, any> = {}
        const defaultMsg = 'Verplicht veld!'

        if (!this.name) errors.name = defaultMsg

        return errors
    }
}
