import { Grid, GridProps } from '@mui/material'
import CKImageUpload from 'components/Form/CKImageUpload'
import MKTypography from 'components/MaterialKit/MKTypography'
import PendingImage from 'models/PendingImage'
import { HasEditableImage } from 'models/interfaces/Images'
import { EditableImage } from 'models/types/ImageTypes'
import { FC, useState } from 'react'

interface Props extends GridProps {
    label: string
    getValue: () => EditableImage
    setValue: (value: EditableImage) => void
    urlValue: () => string | undefined
    model: HasEditableImage
}

const CKEditImageField: FC<Props> = ({ label, getValue, setValue, urlValue, ...gridProps }) => {
    const currentValue = getValue()
    const [previewUrl, setPreviewUrl] = useState<string | undefined>(urlValue())

    const handleFileSelect = (file: File | null) => {
        if (file === null) {
            setValue(undefined)
            setPreviewUrl(undefined)
            return
        }

        const pendingImage = new PendingImage({ file })
        setValue(pendingImage)
        setPreviewUrl(pendingImage.$previewUrl)
    }

    return (
        <Grid item {...gridProps}>
            <MKTypography variant="caption">{label}</MKTypography>
            <CKImageUpload
                onFileSelect={handleFileSelect}
                fieldHasImage={Boolean(currentValue)}
                displayUrl={previewUrl || urlValue() || ''}
            />
        </Grid>
    )
}

export default CKEditImageField
