import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKEventList from 'components/UI/CKEventList'
import { CKPageBody, CKPageTitle } from 'components/UI/CKPageContent'
import CKSocials from 'components/UI/CKSocials'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import UserService, { useArtist } from 'services/UserService'
import { UserPageContainer } from '../UserPageContainer'

interface Props {}

const PageUserDetail: FC<Props> = () => {
    const userId = useParams()['userId']
    const { user, isLoading: loading } = useArtist(userId)

    const UserLabels = () => {
        const labels = []
        if (user?.$isOpenMicer) labels.push("Open-Mic'er")
        if (user?.$isMC) labels.push('MC')
        if (user?.$isOrganizer) labels.push('Organisator')

        return (
            <MKTypography variant="button" color="info" className="text-center" fontWeight="bold">
                {labels.join(' / ')}
            </MKTypography>
        )
    }

    return (
        <>
            <UserPageContainer user={user} loading={loading}>
                <CKPageTitle>
                    {user?.$first_name} {user?.$last_name}
                </CKPageTitle>
                <UserLabels />
                <CKPageBody center>
                    {/* <MKBox mb={3}>
                        {user?.$comedy_tags.map((comedyTag) => {
                            const id = 'comedy-badge-' + comedyTag.$id + '-id'
                            return (
                                <React.Fragment key={'comedy-badge-' + comedyTag.$id}>
                                    <MKBadge
                                        badgeContent={comedyTag.$name}
                                        size="lg"
                                        className="mb-2"
                                        id={id}
                                    />
                                    <UncontrolledTooltip target={id}>
                                        {comedyTag.$description}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )
                        })}
                    </MKBox> */}
                    <MKBox mb={4}>
                        <CKSocials socials={user?.$socials} />
                    </MKBox>
                    <MKBox mb={3}>{user?.$description}</MKBox>
                    <CKEventList model={user} upcomingEventsFunction={UserService.upcomingEvents} />
                    {/* <div style={{ marginLeft: "-40px", marginRight: "-40px" }}>
								<CKEventsSlideshow user={user} />
							</div> */}
                </CKPageBody>
            </UserPageContainer>
        </>
    )
}
export default PageUserDetail
