import bgImage from 'assets/images/bar_sidebar.jpg'
import { CKLoading } from 'components/Generic/CKLoading'
import { CKHero } from 'components/UI/CKHero'
import { LocationIcon } from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent } from 'components/UI/CKPageContent'
import Location from 'models/Location'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
export interface ILocationPageContainer {
    location?: Location
    children: any
    loading: boolean
    adminPanel?: boolean
}

export const LocationPageContainer: FC<ILocationPageContainer> = ({
    location,
    loading,
    adminPanel,
    children,
}) => {
    const history = useHistory()
    if (adminPanel && location && !location.hasRight('FULL')) {
        history.replace(location.$detailUrl)
    }

    return (
        <>
            <CKHero
                size={adminPanel ? 'xxs' : 'md'}
                bgImage={location && location.$banner ? location.$bannerUrl : bgImage}
            />
            <CKPageContent>
                {loading ? (
                    <CKLoading />
                ) : (
                    <>
                        {location?.$id && location?.$logoUrl ? (
                            <CKPageBadge src={location.$logoUrl}></CKPageBadge>
                        ) : (
                            <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                                <LocationIcon fontSize="large" style={{ color: 'white' }} />
                            </CKPageBadge>
                        )}
                        {children}
                    </>
                )}
            </CKPageContent>
        </>
    )
}
