import { Grid } from '@mui/material'
import { CKForm, CKFormBody } from 'components/Form/CKForm'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { CKMarkdownEditor } from 'components/Form/CKMarkdownEditor'
import MKInput from 'components/MaterialKit/MKInput'
import Organisation from 'models/Organisation'
import { FC } from 'react'

interface Props {
    organisation: Organisation
    errors: Record<string, string>
}

export const GeneralForm: FC<Props> = ({ organisation, errors }) => {
    if (!organisation) return null
    return (
        <CKForm>
            <CKFormBody>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MKInput
                            label="Naam"
                            title="Naam"
                            error={errors['name'] ? true : false}
                            defaultValue={organisation.$name}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                organisation.$name = e.target.value
                            }}
                            required
                            fullWidth
                        />
                        <CKFormFeedback>{errors['name']}</CKFormFeedback>
                    </Grid>
                    <Grid item xs={12}>
                        <CKMarkdownEditor
                            markdown={organisation.$description ?? ''}
                            onChange={(markdown) => {
                                organisation.$description = markdown
                            }}
                        />
                    </Grid>
                </Grid>
            </CKFormBody>
        </CKForm>
    )
}
