import { Grid } from '@mui/material'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'
import { ABOUT_LINKS } from '../constants'
import { FooterLink } from './FooterLink'

export const AboutColumn: FC = () => (
    <Grid item xs={12} md={4} className="d-flex flex-column align-items-center" gap={1}>
        <MKTypography variant="h6" color="dark">
            Over
        </MKTypography>
        {ABOUT_LINKS.map((link) => (
            <FooterLink key={link.href} {...link} />
        ))}
    </Grid>
) 