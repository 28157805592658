import AuthService, { ckFetcher } from './AuthService'
import { STATISTICS } from './Constants'
import useSWR from 'swr'

export function useStatistics() {
  const { data, error, mutate } = useSWR(STATISTICS, ckFetcher, {
    refreshInterval: 60000, // Optional: refresh every minute
    revalidateOnFocus: false
  })

  return {
    statistics: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

const MetaTagService = {
    get: async function (path: string) {
        const resp = await AuthService.get(`meta?path=${btoa(path)}`)
        return resp.data
    },
    statistics: async function () {
        const resp = await AuthService.get(STATISTICS)
        return resp.data
    },
}

export default MetaTagService
