import { Grid } from '@mui/material'
import CKEditImageField from 'components/Form/CKEditImageField'
import Location from 'models/Location'
import { FC } from 'react'

interface Props {
    location: Location
}

const LocationImageForm: FC<Props> = ({ location }) => {
    return (
        <Grid container spacing={3}>
            <CKEditImageField
                xs={12}
                md={4}
                label="Logo"
                getValue={() => location.$logo}
                setValue={(value) => {
                    location.$logo = value
                }}
                model={location}
                urlValue={() => location.$logoUrl}
            />
            <CKEditImageField
                xs={12}
                md={8}
                label="Banner"
                getValue={() => location.$banner}
                setValue={(value) => {
                    location.$banner = value
                }}
                model={location}
                urlValue={() => location.$bannerUrl}
            />
        </Grid>
    )
}

export default LocationImageForm
