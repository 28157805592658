// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-table {
  background-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  overflow-x: auto;
}
.ck-table table {
  width: 100%;
}
.ck-table table td.shrink,
.ck-table table th.shrink {
  white-space: nowrap;
}
.ck-table table th.grow,
.ck-table table td.grow {
  width: 99%;
}
.ck-table .ck-table-head {
  display: table-header-group;
}
.ck-table .MuiTableCell-head {
  border: none;
  background-color: transparent;
  color: #1a73e8;
}
.ck-table .MuiTableCell-root {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CKTable/style.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,2BAAA;EACA,WAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;AACR;AACQ;;EAEI,mBAAA;AACZ;AAEQ;;EAEI,UAAA;AAAZ;AAII;EACI,2BAAA;AAFR;AAKI;EACI,YAAA;EACA,6BAAA;EACA,cAAA;AAHR;AAMI;EACI,YAAA;AAJR","sourcesContent":[".ck-table {\n    background-color: transparent !important;\n    box-shadow: none !important;\n    width: 100%;\n    overflow-x: auto;\n\n    table {\n        width: 100%;\n\n        td.shrink,\n        th.shrink {\n            white-space: nowrap;\n        }\n\n        th.grow,\n        td.grow {\n            width: 99%;\n        }\n    }\n\n    .ck-table-head {\n        display: table-header-group;\n    }\n\n    .MuiTableCell-head {\n        border: none;\n        background-color: transparent;\n        color: #1a73e8;\n    }\n\n    .MuiTableCell-root {\n        border: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
