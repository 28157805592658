import { TableHead, TableRow } from '@mui/material'
import { FC } from 'react'

interface Props {
    children: any
    [rest: string]: any
}
const CKTableHead: FC<Props> = ({ children, ...rest }) => {
    const className = rest['className'] + ' p-0 ck-table-head'
    delete rest['className']

    return (
        <TableHead className={className} {...rest}>
            <TableRow>{children}</TableRow>
        </TableHead>
    )
}
export default CKTableHead
