// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-markdown-editor {
  border: 1px solid rgb(123, 128, 154);
  border-radius: 6px;
  min-height: 400px;
}
.ck-markdown-editor p {
  font-size: 1rem;
}
.ck-markdown-editor h1 {
  color: var(--bs-primary);
  font-size: 1.75rem;
  font-weight: 500;
}
.ck-markdown-editor h2 {
  color: var(--bs-primary);
  font-size: 1.5rem;
  font-weight: 400;
}
.ck-markdown-editor h3 {
  color: var(--bs-primary);
  font-size: 1.25rem;
  font-weight: 300;
}

.mdx-editor-toolbar {
  background-color: var(--bs-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/Form/CKMarkdownEditor/style.scss"],"names":[],"mappings":"AAAA;EAuBI,oCAAA;EACA,kBAAA;EACA,iBAAA;AArBJ;AAHI;EACI,eAAA;AAKR;AAFI;EACI,wBAAA;EACA,kBAAA;EACA,gBAAA;AAIR;AADI;EACI,wBAAA;EACA,iBAAA;EACA,gBAAA;AAGR;AAAI;EACI,wBAAA;EACA,kBAAA;EACA,gBAAA;AAER;;AAMA;EACI,mCAAA;AAHJ","sourcesContent":[".ck-markdown-editor {\n    p {\n        font-size: 1rem;\n    }\n\n    h1 {\n        color: var(--bs-primary);\n        font-size: 1.75rem;\n        font-weight: 500;\n    }\n\n    h2 {\n        color: var(--bs-primary);\n        font-size: 1.5rem;\n        font-weight: 400;\n    }\n\n    h3 {\n        color: var(--bs-primary);\n        font-size: 1.25rem;\n        font-weight: 300;\n    }\n\n    border: 1px solid rgb(123, 128, 154);\n    border-radius: 6px;\n    min-height: 400px;\n}\n\n.mdx-editor-toolbar {\n    background-color: var(--bs-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
