import { EditCalendar } from '@mui/icons-material'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKBox from 'components/MaterialKit/MKBox'
import { CKHero } from 'components/UI/CKHero'
import { ImageIcon, InfoIcon, ShareIcon } from 'components/UI/CKIcons'
import CKLoadingModel from 'components/UI/CKLoadingModel'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useWorkshopEdit } from 'services/Workshop/UseWorkshopEdit'
import GeneralForm from './GeneralForm'
import WorkshopImageForm from './WorkshopImageForm'

const PageWorkshopEdit: FC = () => {
    const { workshopId } = useParams<{ workshopId: string }>()
    const { workshop, loading, saving, save, errors } = useWorkshopEdit(workshopId)

    const sidebarTabs = {
        root: {
            general: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm workshop={workshop} errors={errors} />,
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: workshop && <WorkshopImageForm workshop={workshop} />,
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={workshop} />,
            },
        },
    }

    if (!workshop) {
        return <CKLoadingModel loading={loading} notFoundMessage="Workshop niet gevonden" />
    }

    return (
        <MKBox>
            <CKHero size="md" bgImage={workshop.$bannerUrl} />
            <CKPageContent>
                <CKPageBadge src={workshop.$logoUrl}>
                    {!workshop.$logo && (
                        <EditCalendar fontSize="large" style={{ color: 'white' }} />
                    )}
                </CKPageBadge>
                <CKPageTitle>
                    {workshopId === 'new' ? 'Creëer een workshop' : 'Pas workshop aan'}
                </CKPageTitle>
                <CKSidebarTabs
                    sections={sidebarTabs}
                    errors={errors}
                    saving={saving}
                    onSave={save}
                    saveText={workshopId === 'new' ? 'Creëer workshop' : 'Opslaan'}
                    hideGroup
                />
            </CKPageContent>
        </MKBox>
    )
}

export default PageWorkshopEdit
