import { Container, Grid } from '@mui/material'
import MKInfoCard from 'components/MaterialKit/MKInfoCard'
import { LocationMarkerIcon, MicIcon, WorkshopIcon } from 'components/UI/CKIcons'

export const QuickActions = () => {
    return (
        <Container>
            <Grid container spacing={3} className="py-3">
                <Grid item xs={12} lg={4}>
                    <MKInfoCard
                        color="info"
                        icon={<MicIcon />}
                        title="Artiesten"
                        description="Anders moet ge hier eens zien, ge gaat sowieso iemand vinden dat ge leuk vindt! Ontdek het talent van Vlaanderen!"
                        action={{
                            type: 'internal',
                            route: '/artists',
                            label: 'Bekijk de artiesten',
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MKInfoCard
                        color="info"
                        icon={<LocationMarkerIcon />}
                        title="Locaties"
                        description="Nog geen idee waar je wil optreden of gewoon lachen? Check hier onze comedy locaties!"
                        action={{
                            type: 'internal',
                            route: '/locations',
                            label: 'Bekijk de locaties',
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <MKInfoCard
                        color="info"
                        icon={<WorkshopIcon />}
                        title="Workshops"
                        description="Starten met comedy of wil je je skills bijschaven? Ontdek hier een workshop in jouw buurt!"
                        action={{
                            type: 'internal',
                            route: '/workshops',
                            label: 'Bekijk de workshops',
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
