import { Grid } from '@mui/material'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { CKMarkdownEditor } from 'components/Form/CKMarkdownEditor'
import MKInput from 'components/MaterialKit/MKInput'
import Address from 'models/Address'
import Location from 'models/Location'
import { FC } from 'react'

interface Props {
    location: Location
    errors: Record<string, any>
}

const GeneralForm: FC<Props> = ({ location, errors }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <MKInput
                    label="Naam"
                    type="text"
                    defaultValue={location.$name}
                    onChange={(e) => (location.$name = e.target.value)}
                    error={Boolean(errors['name'])}
                    fullWidth
                    required
                />
                <CKFormFeedback>{errors['name']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12}>
                <CKMarkdownEditor
                    markdown={location.$description ?? ''}
                    onChange={(markdown) => {
                        location.$description = markdown
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <CKAddressPicker
                    label="Adres"
                    address={location.$address}
                    error={errors['location']}
                    disableFullWidth
                    onSelect={(address: Address) => {
                        location.$address = address
                    }}
                />
                <CKFormFeedback>{errors['location']}</CKFormFeedback>
            </Grid>
        </Grid>
    )
}

export default GeneralForm
