import { useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { CKDesktopSidebarTabs } from './DesktopSidebarTabs'
import { Footer } from './Footer'
import { CKMobileSidebarTabs } from './MobileSidebarTabs'
import './style.scss'
import { CKSidebarTabsProps } from './types'
import { processTabSections } from './utils'

export const CKSidebarTabs: FC<CKSidebarTabsProps> = ({
    sections,
    errors = {},
    hideGroup,
    hideTitle,
    customFooter,
    hideFooter = false,
    ...footerProps
}) => {
    const isMobileSize = useMediaQuery('(max-width:768px)')
    const [previousErrors, setPreviousErrors] = useState(errors)
    const [hasNewErrors, setHasNewErrors] = useState(false)

    useEffect(() => {
        if (!sections.root) return

        const hadErrors = Object.keys(previousErrors).length > 0
        const hasErrors = Object.keys(errors).length > 0

        if (!hadErrors && hasErrors) {
            setHasNewErrors(true)
        }

        setPreviousErrors(errors)
    }, [errors, sections.root])

    const processedSections = processTabSections(sections, errors)
    const footer = hideFooter ? false : customFooter || <Footer {...footerProps} />

    return (
        <div className="ck-sidebar-tabs">
            {isMobileSize ? (
                <CKMobileSidebarTabs
                    sections={processedSections}
                    customFooter={footer}
                    hideGroup={hideGroup}
                    hasNewErrors={hasNewErrors}
                    onErrorTabShown={() => setHasNewErrors(false)}
                />
            ) : (
                <CKDesktopSidebarTabs
                    sections={processedSections}
                    customFooter={footer}
                    hideGroup={hideGroup}
                    hasNewErrors={hasNewErrors}
                    onErrorTabShown={() => setHasNewErrors(false)}
                />
            )}
        </div>
    )
}
