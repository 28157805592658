import LocationOnIcon from '@mui/icons-material/LocationOn'
import CKDeleteModelButton from 'components/Buttons/CKDeleteModelButton'
import CKEditModelButton from 'components/Buttons/CKEditModelButton'
import { CKElevatedUsersMenuItem, CKElevatedUsersModal } from 'components/Buttons/CKElevatedUsers'
import CKSettingsButton from 'components/Buttons/CKSettingsButton'
import CKShareButtons from 'components/Buttons/CKShareButtons'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import { MarkdownViewer } from 'components/Form/CKMarkdownViewer'
import MKBox from 'components/MaterialKit/MKBox'
import CKEventList from 'components/UI/CKEventList'
import { CKPageBody, CKPageTitle } from 'components/UI/CKPageContent'
import CKSocials from 'components/UI/CKSocials'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import LocationService, { useLocation } from 'services/Location/LocationService'
import { LocationPageContainer } from '../LocationPageContainer'

interface Props {}

const PageLocationDetail: FC<Props> = () => {
    const locationId = useParams()['locationId']
    const { location, isLoading: loading } = useLocation(locationId)
    const [elevatedUsersOpen, setElevatedUsersOpen] = useState(false)

    return (
        <LocationPageContainer location={location} loading={loading}>
            <CKPageTitle>{location?.$name}</CKPageTitle>
            <div className="d-flex p-3 gap-3 justify-content-center">
                <CKSettingsButton hideWhenEmpty>
                    {location?.hasRight('FULL') && (
                        <CKEditModelButton className="h-100 w-100" model={location} menuItem />
                    )}
                    {location?.isOwner() && (
                        <CKElevatedUsersMenuItem
                            onClick={(e) => {
                                e.preventDefault()
                                setElevatedUsersOpen(true)
                            }}
                        />
                    )}
                    {location?.isOwner() && (
                        <CKDeleteModelButton className="h-100 w-100" model={location} />
                    )}
                </CKSettingsButton>
                <CKElevatedUsersModal
                    model={location}
                    open={elevatedUsersOpen}
                    onClose={() => {
                        setElevatedUsersOpen(false)
                    }}
                />
            </div>
            <div className="text-center mt-3">
                <CKShareButtons />
            </div>
            <CKPageBody center>
                <MKBox className="mt-4 mx-auto text-center" px={{ sm: 0, md: 10 }}>
                    <MarkdownViewer content={location?.$description} />
                </MKBox>
                <MKBox mt={3}>
                    <CKSocials socials={location?.$socials} />
                </MKBox>
                <CKAddressPicker
                    className="mt-4"
                    disabled={true}
                    label={
                        <div className="d-flex align-items-center justify-content-center">
                            <LocationOnIcon className="me-2" />
                            Adres
                        </div>
                    }
                    address={location?.$address}
                    showNavigate={true}
                />
                <MKBox mt={4}>
                    <CKEventList
                        model={location}
                        upcomingEventsFunction={LocationService.upcomingEvents}
                    />
                </MKBox>
            </CKPageBody>
        </LocationPageContainer>
    )
}
export default PageLocationDetail
