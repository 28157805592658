/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react'

// react-router components
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Collapse from '@mui/material/Collapse'
import MuiLink from '@mui/material/Link'

// Material Kit 2 React components
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'

// Material Kit 2 React example components
import CKDarkModeToggle from 'components/Buttons/CKDarkModeToggle'
import MKButton from 'components/MaterialKit/MKButton'
import DefaultNavbarDropdown from 'examples/Navbars/DefaultNavbar/DefaultNavbarDropdown'

function DefaultNavbarMobile({ routes, open, action, darkModeToggle, onNavigate }) {
    const [collapse, setCollapse] = useState('')

    const handleSetCollapse = (name) => (collapse === name ? setCollapse(false) : setCollapse(name))

    const renderNavbarItems = routes.map(
        (
            {
                name,
                icon,
                collapse: routeCollapses,
                href,
                route,
                collapse: navCollapse,
                hide,
                divider,
                action,
            },
            index
        ) => {
            if (hide) return

            return (
                <DefaultNavbarDropdown
                    key={'navbar-dropdown-' + index}
                    name={name}
                    icon={icon}
                    isMobile={true}
                    collapseStatus={name === collapse}
                    onClick={() => {
                        if (onNavigate && route) onNavigate()
                        handleSetCollapse(name)
                    }}
                    action={action}
                    href={href}
                    divider={divider}
                    route={route}
                    collapse={Boolean(navCollapse)}
                >
                    {routeCollapses && (
                        <MKBox sx={{ maxHeight: '15rem', overflowY: 'scroll' }}>
                            {routeCollapses.map((item, itemIndex) => {
                                const action = Boolean(item.action)

                                const actionComponent = {
                                    onClick: (e) => {
                                        item.action(e)
                                    },
                                }
                                return (
                                    <MKBox key={'menu-' + itemIndex} px={2}>
                                        {item.collapse ? (
                                            <>
                                                <MKTypography
                                                    display="block"
                                                    variant="button"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                    py={1}
                                                    px={0.5}
                                                >
                                                    {item.name}
                                                </MKTypography>
                                                {item.collapse.map((el, index) => (
                                                    <MKTypography
                                                        key={'menu-item-' + itemIndex + '-' + index}
                                                        component={el.route ? Link : MuiLink}
                                                        to={el.route ? el.route : ''}
                                                        href={el.href ? el.href : ''}
                                                        target={el.href ? '_blank' : ''}
                                                        rel={el.href ? 'noreferrer' : 'noreferrer'}
                                                        minWidth="11.25rem"
                                                        display="block"
                                                        variant="button"
                                                        color="text"
                                                        textTransform="capitalize"
                                                        fontWeight="regular"
                                                        py={0.625}
                                                        px={2}
                                                        sx={({
                                                            palette: { grey, dark },
                                                            borders: { borderRadius },
                                                        }) => ({
                                                            borderRadius: borderRadius.md,
                                                            cursor: 'pointer',
                                                            transition: 'all 300ms linear',

                                                            '&:hover': {
                                                                backgroundColor: grey[200],
                                                                color: dark.main,
                                                            },
                                                        })}
                                                    >
                                                        {el.name}
                                                    </MKTypography>
                                                ))}
                                            </>
                                        ) : (
                                            <MKBox
                                                // key={item.key}
                                                display="block"
                                                {...(action && actionComponent)}
                                                component={
                                                    action ? undefined : item.route ? Link : MuiLink
                                                }
                                                to={item.route ? item.route : ''}
                                                href={item.href ? item.href : ''}
                                                target={item.href ? '_blank' : ''}
                                                rel={item.href ? 'noreferrer' : 'noreferrer'}
                                                sx={({
                                                    palette: { grey, dark },
                                                    borders: { borderRadius },
                                                }) => ({
                                                    borderRadius: borderRadius.md,
                                                    cursor: 'pointer',
                                                    transition: 'all 300ms linear',
                                                    py: 1,
                                                    px: 1.625,

                                                    '&:hover': {
                                                        backgroundColor: grey[200],
                                                        color: dark.main,

                                                        '& *': {
                                                            color: dark.main,
                                                        },
                                                    },
                                                })}
                                            >
                                                <MKTypography
                                                    display="block"
                                                    variant="button"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                    onClick={() => {
                                                        if (onNavigate) onNavigate()
                                                    }}
                                                >
                                                    {item.name}
                                                </MKTypography>
                                                <MKTypography
                                                    display="block"
                                                    variant="button"
                                                    color="text"
                                                    fontWeight="regular"
                                                    sx={{
                                                        transition: 'all 300ms linear',
                                                    }}
                                                >
                                                    {item.description}
                                                </MKTypography>
                                            </MKBox>
                                        )}
                                    </MKBox>
                                )
                            })}
                        </MKBox>
                    )}
                </DefaultNavbarDropdown>
            )
        }
    )

    const renderAction = (
        <MKBox className="text-center mt-3">
            {action &&
                (action.type === 'internal' ? (
                    <MKButton
                        component={action.route ? Link : MuiLink}
                        to={action.route}
                        onClick={() => {
                            if (action.action) {
                                action.action()
                            }
                            if (onNavigate) {
                                onNavigate()
                            }
                        }}
                        variant={
                            action.color === 'white' || action.color === 'default'
                                ? 'contained'
                                : 'gradient'
                        }
                        color={action.color ? action.color : 'info'}
                    >
                        {action.label}
                    </MKButton>
                ) : (
                    <MKButton
                        component="a"
                        href={action.route}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            if (action.action) {
                                action.action()
                            }
                            if (onNavigate) {
                                onNavigate()
                            }
                        }}
                        variant={
                            action.color === 'white' || action.color === 'default'
                                ? 'contained'
                                : 'gradient'
                        }
                        color={action.color ? action.color : 'info'}
                        // size="small"
                    >
                        {action.label}
                    </MKButton>
                ))}
        </MKBox>
    )

    return (
        <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
            <MKBox width="calc(100% + 1.625rem)" mt={2} mb={1} ml={-2}>
                {renderNavbarItems}
                {renderAction}
                {darkModeToggle && (
                    <div className="text-center mt-3">
                        <CKDarkModeToggle variant={'button-text'} />
                    </div>
                )}
            </MKBox>
        </Collapse>
    )
}

DefaultNavbarMobile.defaultProps = {
    darkModeToggle: false,
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    darkModeToggle: PropTypes.bool,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(['external', 'internal']).isRequired,
            route: PropTypes.string,
            action: PropTypes.func,
            color: PropTypes.oneOf([
                'primary',
                'secondary',
                'info',
                'success',
                'warning',
                'error',
                'dark',
                'light',
                'default',
                'white',
            ]),
            label: PropTypes.any.isRequired,
        }),
    ]),
}

export default DefaultNavbarMobile
