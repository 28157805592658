import { Divider, List, ListItem, ListItemText, Switch } from '@mui/material'
import { CKLoading } from 'components/Generic/CKLoading'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import UserService from 'services/UserService'
import { PREFERENCE_EVENT_CHANGES, PREFERENCE_EVENT_NEARBY, PREFERENCE_EVENT_PERMISSION, PREFERENCE_EVENT_REMINDER, PREFERENCE_LINEUP } from '../Constants'

interface Props {}

interface NotificationOption {
    primaryText: string
    secondaryText: string
    disabled?: boolean
    disabledText?: string
}

const EmailNotificationSettings: FC<Props> = () => {
    const {
        preferences,
        isLoading: isLoadingPreferences,
        isError: isErrorPreferences,
    } = UserService.usePreferences()

    const [settings, setSettings] = useState<Record<string, boolean>>({})
    const [saving, setSaving] = useState<boolean>(false)

    const [options, setOptions] = useState<Record<string, NotificationOption>>({
        [PREFERENCE_LINEUP]: {
            primaryText: 'Line-up',
            secondaryText:
                'Wijzigingen van jouw spots (confirmatie, annulering, tijdstip wijziging)',
        },
        [PREFERENCE_EVENT_CHANGES]: {
            primaryText: 'Evenement wijziging',
            secondaryText: 'Wijzigingen in het evenement (tijdstippen - locatie)',
        },
        [PREFERENCE_EVENT_NEARBY]: {
            primaryText: 'Evenement in de buurt',
            secondaryText: 'Krijg een mail als er een evenemnt in jouw buurt is',
            disabled: false,
            disabledText: 'Je moet eerst een adres instellen voor we je een melding kunnen sturen',
        },
        [PREFERENCE_EVENT_REMINDER]: {
            primaryText: 'Evenement herinnering',
            secondaryText: 'Reminder 24u voordat een evenement begint',
        },
        [PREFERENCE_EVENT_PERMISSION]: {
            primaryText: 'Evenement rechten',
            secondaryText: 'Als je rechten verandert zijn voor een evenement',
        },
    })

    useEffect(() => {
        const fetchAddress = async () => {
            const address = await UserService.getMyAddress()
            if (address === undefined) {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [PREFERENCE_EVENT_NEARBY]: {
                        ...prevOptions[PREFERENCE_EVENT_NEARBY],
                        disabled: true,
                    },
                }))
            }
        }

        fetchAddress()
    }, [])

    useEffect(() => {
        if (preferences) {
            setSettings(preferences.email || {})
        }
    }, [preferences])

    const save = useCallback(async () => {
        setSaving(true)

        try {
            await UserService.updatePreferences({ email: settings })
        } catch (e) {
            console.error(e)
        }

        setSaving(false)
    }, [settings])

    const toggleSetting = useCallback((option: string) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [option]: prevSettings[option] !== undefined ? !prevSettings[option] : false,
        }))
    }, [])

    const notificationOption = useCallback(
        (option: string, isLastElement: boolean) => (
            <Fragment key={`notification-${option}`}>
                <ListItem
                    secondaryAction={
                        <Switch
                            id={`notification-mobile-option-${option}`}
                            color="primary"
                            disabled={options[option].disabled}
                            checked={
                                !options[option].disabled && settings.hasOwnProperty(option)
                                    ? settings[option]
                                    : true
                            }
                            onChange={() => toggleSetting(option)}
                        />
                    }
                >
                    <ListItemText
                        primary={options[option].primaryText}
                        secondary={options[option].secondaryText}
                        primaryTypographyProps={{
                            fontSize: 'h6.fontSize',
                            fontWeight: 'bold',
                        }}
                        secondaryTypographyProps={{
                            fontSize: '14px',
                        }}
                    />
                </ListItem>
                {options[option].disabled && options[option].disabledText && (
                    <MKTypography variant="caption" color="error">
                        {options[option].disabledText}
                    </MKTypography>
                )}
                {!isLastElement && <Divider />}
            </Fragment>
        ),
        [options, settings, toggleSetting]
    )

    const notificationOptions = useMemo(
        () =>
            Object.keys(options).map((option, index) => {
                const isLastElement = index + 1 === Object.keys(options).length
                return notificationOption(option, isLastElement)
            }),
        [options, notificationOption]
    )

    if (isLoadingPreferences) return <CKLoading />
    if (isErrorPreferences) return <div>Error loading data</div>

    return (
        <div className="">
            <List>{notificationOptions}</List>
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </div>
    )
}

export default EmailNotificationSettings