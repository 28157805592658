import CKModel from './CKModel'

export default class ComedyTag extends CKModel {
    private description: string

    constructor(id: string | undefined = undefined, name: string = '', description: string = '') {
        super(id)
        this.name = name
        this.description = description
    }

    public static fromJSON(json: any): ComedyTag {
        let result = new ComedyTag()
        result = Object.assign(result, json)

        return result
    }

    public get $description(): string {
        return this.description
    }
    public set $description(value: string) {
        this.description = value
    }

    public get $className(): string {
        return 'ComedyTag'
    }

    public validate(): Record<string, any> {
        const errors: Record<string, any> = {}

        if (!this.name) errors.name = 'Name is required'

        return errors
    }
}
