import { Grid } from '@mui/material'
import CKDatePicker from 'components/Form/CKDatePicker'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { FC, useMemo, useState } from 'react'

export interface IEventTimes {
    start?: Date
    arrival?: Date
    errors?: any[]
    onChange?: (start: Date, arrival: Date) => void
}

export const EventTimes: FC<IEventTimes> = ({
    start: defaultStart,
    arrival: defaultArrival,
    onChange: propsOnChange,
    errors,
}) => {
    const [start, setStart] = useState<Date>(defaultStart ? new Date(defaultStart) : null)
    const [arrival, setArrival] = useState<Date>(defaultArrival ? new Date(defaultArrival) : null)

    useMemo(() => {
        if (propsOnChange) {
            propsOnChange(start, arrival)
        }
    }, [start, arrival, propsOnChange])

    const onDateChange = (date: Date) => {
        const _start = new Date(date)
        const _arrival = new Date(date)
        if (start) {
            _start.setHours(start.getHours())
            _start.setMinutes(start.getMinutes())
        }

        if (arrival) {
            _arrival.setHours(arrival.getHours())
            _arrival.setMinutes(arrival.getMinutes())
        }

        setStart(_start)
        setArrival(_arrival)
    }

    return (
        <>
            <Grid item xs={12} md={4}>
                <CKDatePicker label="Datum" required defaultValue={start} onChange={onDateChange} />
                <CKFormFeedback>{errors['date']}</CKFormFeedback>
            </Grid>
            <Grid item xs={12} md={4}>
                <CKDatePicker
                    label="Starttijd"
                    timeOnly
                    required
                    defaultValue={start}
                    onChange={(e) => {
                        const _date = new Date(e)
                        if (start) {
                            start.setHours(_date.getHours())
                            start.setMinutes(_date.getMinutes())

                            const _arrival = new Date(_date)
                            _arrival.setMinutes(_arrival.getMinutes() - 30)
                            setArrival(_arrival)
                        } else {
                            setStart(_date)
                        }
                    }}
                />
                <CKFormFeedback>{errors['start_time']}</CKFormFeedback>
            </Grid>

            <Grid item xs={12} md={4}>
                <CKDatePicker
                    label="Aankomsttijd"
                    timeOnly
                    required
                    defaultValue={arrival}
                    onChange={(e) => {
                        const _date = new Date(e)
                        if (arrival) {
                            arrival.setHours(_date.getHours())
                            arrival.setMinutes(_date.getMinutes())
                        } else {
                            setArrival(_date)
                        }
                    }}
                />
                <CKFormFeedback>{errors['arrival_time']}</CKFormFeedback>
            </Grid>
        </>
    )
}
