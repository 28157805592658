import { FC } from 'react'
import MKTypography from 'components/MaterialKit/MKTypography'
import EventNotification from 'models/Notifications/EventNotification'
import { NotificationTypeProps, NotificationTypeComponent } from './types'
import EventNotificationLogo from '../Logos/EventNotificationLogo'

const Text: FC<NotificationTypeProps> = ({ notification }) => {
    const eventNotification = notification as EventNotification

    const message = (() => {
        switch (eventNotification.$title) {
            case "Toegangsrechten ingetrokken":
                return `Je toegangsrechten zijn ingetrokken voor`;
            case "Nieuwe toegangsrechten verleend":
                return `Je hebt toegangsrechten gekregen tot`;
            case "Toegangsrechten aangepast":
                return `Je toegangsrechten zijn aangepast voor`;
            default:
                return `Er is iets gewijzigd aan je toegangsrechten voor ${eventNotification.$event.$name}`;
        }
    })();
    
    return (
        <MKTypography variant="body2" color="text" display="inline">
            {message} <b>{eventNotification.$event.$name}</b>
        </MKTypography>
    )
}

const Logo: FC<NotificationTypeProps> = ({ notification }) => {
    const eventNotification = notification as EventNotification
    return <EventNotificationLogo notification={eventNotification} />
}

export const EventPermissions: NotificationTypeComponent = {
    Text,
    Logo,
} 