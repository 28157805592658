import { Container } from '@mui/material'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import { ImageIcon, InfoIcon, MoneyIcon, ShareIcon } from 'components/UI/CKIcons'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { TabSections } from 'components/UI/CKSidebarTabs/types'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useOrganisationEdit } from 'services/Organisation/UseOrganisationEdit'
import { OrganisationPageContainer } from '../OrganisationPageContainer'
import { GeneralForm } from './GeneralForm'
import { MollieConnect } from './MollieConnect'
import OrganisationImageForm from './OrganisationImageForm'

interface Props {}

const PageOrganisationEdit: FC<Props> = () => {
    const { organisationId } = useParams<{ organisationId: string }>()
    const { organisation, loading, saving, save, cancel, errors } =
        useOrganisationEdit(organisationId)

    const sidebarSteps: TabSections = {
        root: {
            algemeen: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: <GeneralForm organisation={organisation} errors={errors} />,
                errorFields: ['name', 'description', 'email'],
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: organisation && <OrganisationImageForm organisation={organisation} />,
                errorFields: ['logo', 'banner'],
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={organisation} />,
                errorFields: ['socials'],
            },
            payments: {
                title: 'Betalingen',
                icon: <MoneyIcon />,
                component: <MollieConnect organisation={organisation} />,
                errorFields: ['mollie'],
            },
        },
    }

    return (
        <OrganisationPageContainer organisation={organisation} loading={loading} adminPanel>
            <CKPageTitle>
                {organisationId === 'new' ? 'Creëer een organisatie' : 'Pas organisatie aan'}
            </CKPageTitle>
            <Container className="my-3">
                <CKSidebarTabs
                    sections={sidebarSteps}
                    errors={errors}
                    saving={saving}
                    onSave={save}
                    onCancel={cancel}
                    hideGroup
                />
            </Container>
            {/* </CKPageContent>
                    </>
                ) : (
                    <CKLoadingModel loading={loading} notFoundMessage="Organisatie niet gevonden" />
                )}
            </MKBox> */}
        </OrganisationPageContainer>
    )
}
export default PageOrganisationEdit
