import bgImage from 'assets/images/bar_sidebar.jpg'
import { CKLoading } from 'components/Generic/CKLoading'
import MKAlert from 'components/MaterialKit/MKAlert'
import { CKHero } from 'components/UI/CKHero'
import { OrganisationIcon } from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent } from 'components/UI/CKPageContent'
import Organisation from 'models/Organisation'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
export interface IOrganisationPageContainer {
    organisation?: Organisation
    children: any
    loading: boolean
    adminPanel?: boolean
}

export const OrganisationPageContainer: FC<IOrganisationPageContainer> = ({
    organisation,
    loading,
    children,
    adminPanel,
}) => {
    const history = useHistory()
    if (adminPanel && organisation && !organisation.hasRight('FULL')) {
        history.replace(organisation.$detailUrl)
    }

    return (
        <>
            <CKHero
                size={adminPanel ? 'xxs' : 'md'}
                bgImage={organisation && organisation.$banner ? organisation.$bannerUrl : bgImage}
            />
            <CKPageContent>
                {loading ? (
                    <CKLoading />
                ) : (
                    <>
                        {!organisation ? (
                            <MKAlert color="error">Organisatie niet gevonden.</MKAlert>
                        ) : (
                            <>
                                {organisation?.$id && organisation?.$logoUrl ? (
                                    <CKPageBadge src={organisation.$logoUrl}></CKPageBadge>
                                ) : (
                                    <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                                        <OrganisationIcon
                                            fontSize="large"
                                            style={{ color: 'white' }}
                                        />
                                    </CKPageBadge>
                                )}
                            </>
                        )}
                        {children}
                    </>
                )}
            </CKPageContent>
        </>
    )
}
