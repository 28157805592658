import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'
import { FooterLink as FooterLinkType } from '../types'

export const FooterLink: FC<FooterLinkType> = ({ label, href }) => (
    <MKTypography
        component="a"
        variant="button"
        color="info"
        href={href}
    >
        {label}
    </MKTypography>
) 