import { DEFAULT_EVENT_BANNER, DEFAULT_EVENT_LOGO } from 'constants/images'
import CKModel from './CKModel'
import CustomLineupSpot from './CustomLineupSpot'
import EventSpot from './EventSpot'
import Image from './Image'
import Location from './Location'
import Organisation from './Organisation'
import PendingImage from './PendingImage'
import Social from './Social'
import Ticket from './Ticket'
import User from './User'
import { Banner, Logo } from './interfaces/Images'
import { EditableImage } from './types/ImageTypes'

interface EventProps {
    id?: string
    slug?: string
    name?: string
    description?: string
    start_time?: Date
    arrival_time?: Date
    location?: Location
    logo?: Image
    banner?: Image
    spots?: EventSpot[]
    lineupComplete?: boolean
    lineUp?: User[]
    socials?: Social[]
    custom_lineup?: CustomLineupSpot[]
}

export default class Event extends CKModel implements Logo, Banner {
    private description: string | undefined
    private start_time: Date | undefined
    private arrival_time: Date | undefined
    private location: Location | undefined
    private logo: EditableImage
    private banner: EditableImage
    private spots: EventSpot[] | undefined
    private lineupComplete: boolean
    private lineUp: User[] | undefined
    private tickets: Ticket[] | undefined
    private organiser: Organisation | User | undefined
    private custom_lineup: CustomLineupSpot[] | undefined

    private location_id: string | undefined
    private banner_id: string | undefined
    private logo_id: string | undefined
    private organisation_id: string | undefined

    constructor({
        id,
        slug,
        name,
        description,
        start_time,
        arrival_time,
        location,
        logo,
        banner,
        spots,
        lineupComplete = false,
        lineUp,
        socials = [],
        custom_lineup,
    }: EventProps = {}) {
        super(id, slug, socials)

        this.name = name
        this.description = description
        this.start_time = start_time
        this.arrival_time = arrival_time
        this.location = location
        this.logo = logo
        this.banner = banner
        this.spots = spots
        this.lineupComplete = lineupComplete
        this.lineUp = lineUp
        this.custom_lineup = custom_lineup
    }
    get bannerUrl(): string {
        throw new Error('Method not implemented.')
    }

    public static fromJSON(json: any) {
        let result = new Event()
        result = Object.assign(result, json)

        if (json.location) {
            result.$location = Location.fromJSON(json.location)
        }

        if (json.logo) {
            result.$logo = Image.fromJSON(json.logo)
        }

        if (json.banner) {
            result.$banner = Image.fromJSON(json.banner)
        }

        if (json.start_time) {
            result.$start_time = new Date(json.start_time)
        }

        if (json.arrival_time) {
            result.$arrival_time = new Date(json.arrival_time)
        }

        if (json.spots) {
            result.$spots = []
            for (const spot of json.spots) {
                result.$spots.push(EventSpot.fromJSON(spot))
            }
        }

        if (json.tickets) {
            result.tickets = []
            for (const ticket of json.tickets) {
                result.tickets.push(Ticket.fromJSON(ticket))
            }
        }

        result.$lineupComplete = json.lineup_complete ? true : false
        if (json.lineup) {
            result.$lineUp = []
            for (const l of json.lineup) {
                const user = User.fromJSON(l)
                result.$lineUp.push(user)
            }
        } else {
            result.$lineUp = []
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            result.$socials = socials
        }

        if (json.organiser) {
            if (json.organiser.className === 'organisation') {
                result.$organiser = Organisation.fromJSON(json.organiser)
            } else {
                result.$organiser = User.fromJSON(json.organiser)
            }
        }

        if (json.custom_lineup) {
            try {
                if (Array.isArray(json.custom_lineup)) {
                    result.$custom_lineup = []
                    for (const spot of json.custom_lineup) {
                        result.$custom_lineup.push(CustomLineupSpot.fromJSON(spot))
                    }
                } else {
                    console.error('Parsed custom_lineup is not an array:', json.custom_lineup)
                    result.$custom_lineup = []
                }
            } catch (e) {
                console.error('Failed to parse custom_lineup:', e)
                result.$custom_lineup = []
            }
        }

        return result
    }

    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    public get $start_time(): Date | undefined {
        return this.start_time
    }
    public set $start_time(value: Date | undefined) {
        this.start_time = value
    }

    public get $arrival_time(): Date | undefined {
        return this.arrival_time
    }
    public set $arrival_time(value: Date | undefined) {
        this.arrival_time = value
    }

    public get $location(): Location | undefined {
        return this.location
    }
    public set $location(value: Location | undefined) {
        this.location = value
        this.location_id = value?.$id
    }

    public get $organisation(): Organisation | undefined {
        // if organisor is an organisation, return the organisation
        if (this.organiser && this.organiser.$className === 'organisation') {
            return this.organiser as Organisation
        }

        return undefined
    }

    public get $spots(): EventSpot[] | undefined {
        return this.spots
    }
    public set $spots(value: EventSpot[] | undefined) {
        this.spots = value
    }

    public get $lineupComplete(): boolean {
        return this.lineupComplete
    }
    public set $lineupComplete(value: boolean) {
        this.lineupComplete = value
    }

    public get $lineUp(): User[] | undefined {
        return this.lineUp
    }
    public set $lineUp(value: User[] | undefined) {
        this.lineUp = value
    }

    /**
     * Logo
     */
    public get $logo(): EditableImage {
        return this.logo
    }
    public set $logo(value: EditableImage) {
        this.logo = value
        if (value instanceof Image) {
            this.logo_id = value.$id
        } else {
            this.logo_id = undefined
        }
    }
    public get $logoUrl(): string | undefined {
        if (this.$logo instanceof Image) return this.$logo.$url
        if (this.$logo instanceof PendingImage) return this.$logo.$previewUrl
        if (this.$location) return this.$location.$logoUrl
        return DEFAULT_EVENT_LOGO
    }

    /**
     * Banner
     */
    public get $banner(): EditableImage {
        return this.banner
    }
    public set $banner(value: EditableImage) {
        this.banner = value
        if (value instanceof Image) {
            this.banner_id = value.$id
        } else {
            this.banner_id = undefined
        }
    }
    public get $bannerUrl(): string | undefined {
        if (this.$banner instanceof Image) return this.$banner.$url
        if (this.$banner instanceof PendingImage) return this.$banner.$previewUrl
        if (this.$location) return this.$location.$bannerUrl
        return DEFAULT_EVENT_BANNER
    }

    public get $className(): string {
        return 'event'
    }

    public get $tickets(): Ticket[] | undefined {
        return this.tickets
    }
    public set $tickets(value: Ticket[] | undefined) {
        this.tickets = value
    }

    public get $organiser(): Organisation | User | undefined {
        return this.organiser
    }
    public set $organiser(value: Organisation | User | undefined) {
        this.organiser = value
        this.organisation_id = value?.$id
    }

    public get $custom_lineup(): CustomLineupSpot[] | undefined {
        return this.custom_lineup
    }
    public set $custom_lineup(value: CustomLineupSpot[] | undefined) {
        this.custom_lineup = value
    }

    public get $completeCombinedLineup(): Array<CustomLineupSpot | User> {
        // combine this.$lineUp and this.$custom_lineup
        if (!this.$lineUp) return this.$custom_lineup || []
        if (!this.$custom_lineup) return this.$lineUp

        return [...this.$lineUp, ...this.$custom_lineup]
    }

    public showLineup(): boolean {
        return this.$lineupComplete || this.$custom_lineup?.length > 0
    }

    public amountOpenSpots() {
        if (!this.$spots) return 0

        try {
            return (
                this.$spots
                    .map((spot) => spot.$amount)
                    .reduce((acc, amount) => {
                        return acc + amount
                    }) - this.$lineUp.length
            )
        } catch (e) {
            return 0
        }
    }

    public validate(): Record<string, any> {
        const errors: Record<string, any> = {}
        const defaultMsg = 'Verplicht veld!'

        if (!this.name) errors.name = defaultMsg

        if (!this.start_time) {
            errors.date = defaultMsg
            errors.start_time = defaultMsg
        } else if (this.start_time && this.start_time < new Date()) {
            errors.start_time = 'Start tijd moet in de toekomst liggen'
        }

        if (!this.arrival_time) errors.arrival_time = defaultMsg
        else if (this.arrival_time && this.start_time && this.arrival_time > this.start_time) {
            errors.arrival_time = 'Aankomsttijd moet voor start tijd liggen'
        }

        if (!this.location_id) errors.location_id = defaultMsg

        // if (this.spots) {
        //     const spotErrors: Record<string, any>[] = []
        //     console.log(this.spots)
        //     this.spots.forEach((spot, index) => {
        //         const spotError: Record<string, any> = {}
        //         if (!spot.$length) spotError.length = defaultMsg
        //         if (!spot.$amount) spotError.amount = defaultMsg
        //         if (spot.$type && !['MC', 'DEFAULT'].includes(spot.$type)) {
        //             spotError.type = 'Type moet MC of DEFAULT zijn'
        //         }
        //         if (Object.keys(spotError).length > 0) {
        //             spotErrors[index] = spotError
        //         }
        //     })
        //     console.log(spotErrors)
        //     if (spotErrors.length > 0) errors.spots = spotErrors
        // }

        if (this.custom_lineup) {
            const customLineupErrors: Record<string, Record<string, string>> = {}
            let hasErrors = false

            this.custom_lineup.forEach((spot) => {
                const spotError = spot.validate()
                if (Object.keys(spotError).length > 0) {
                    customLineupErrors[spot.$id] = spotError
                    hasErrors = true
                }
            })

            if (hasErrors) {
                errors.custom_lineup = customLineupErrors
            }
        }

        console.log(errors)
        return errors
    }
}
