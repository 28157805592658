/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Card, Grid, Switch } from '@mui/material'
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import CKDatePicker from 'components/Form/CKDatePicker'
import CKEditImageField from 'components/Form/CKEditImageField'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import User from 'models/User'
import { FC, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { Zoom, toast } from 'react-toastify'
import AuthService from 'services/AuthService'
import PasswordUtils from 'utils/PasswordUtils'

const RegisterPage: FC = () => {
    const [user] = useState(new User())
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState<Record<string, string>>({})
    const [saving, setSaving] = useState(false)
    const history = useHistory()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!inputValid()) return

        setSaving(true)
        try {
            await AuthService.registerUser(user, password)
            toast.success('Registratie succesvol! Je kan nu inloggen.', {
                position: 'bottom-center',
                transition: Zoom,
            })
            history.push('/login')
        } catch (error) {
            console.error(error)
            toast.error('Er is iets misgegaan bij het registreren.', {
                position: 'bottom-center',
                transition: Zoom,
            })
        }
        setSaving(false)
    }

    const inputValid = () => {
        const _errors = {}

        if (!user.$first_name) {
            _errors['firstName'] = 'Voornaam is verplicht'
        }
        if (!user.$last_name) {
            _errors['lastName'] = 'Achternaam is verplicht'
        }
        if (!user.$birthdate) {
            _errors['birthdate'] = 'Geboortedatum is verplicht'
        }
        if (!user.$email) {
            _errors['email'] = 'Email is verplicht'
        }
        if (!password) {
            _errors['password'] = 'Wachtwoord is verplicht'
        } else {
            if (!PasswordUtils.isStrongPassword(password)) {
                _errors['password'] = 'Wachtwoord niet sterk genoeg'
            }
        }
        if (password !== confirmPassword) {
            _errors['confirmPassword'] = 'Wachtwoord komt niet overeen'
        }

        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }

    return (
        <>
            <MKBox
                position="fixed"
                top={0}
                left={0}
                zIndex={0}
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                    }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <MKBox width="100%" height="auto" mx="auto" position="relative" zIndex={2}>
                <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Grid item xs={11} sm={9} md={6} lg={5} xl={4} marginY={'15vh'}>
                        <Card>
                            <MKBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MKTypography variant="h4" fontWeight="medium" color="white" my={1}>
                                    Registreren
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={4} pb={3} px={3} component="form" onSubmit={handleSubmit}>
                                <MKBox mb={2}>
                                    <MKInput
                                        type="text"
                                        name="firstName"
                                        label="Voornaam"
                                        defaultValue={user.$first_name}
                                        onChange={(e) => (user.$first_name = e.target.value)}
                                        fullWidth
                                        required
                                        error={errors.hasOwnProperty('firstName')}
                                    />
                                    <CKFormFeedback>{errors['firstName']}</CKFormFeedback>
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput
                                        type="text"
                                        name="lastName"
                                        label="Achternaam"
                                        defaultValue={user.$last_name}
                                        onChange={(e) => (user.$last_name = e.target.value)}
                                        fullWidth
                                        required
                                        error={errors.hasOwnProperty('lastName')}
                                    />
                                    <CKFormFeedback>{errors['lastName']}</CKFormFeedback>
                                </MKBox>
                                <MKBox mb={2}>
                                    <CKDatePicker
                                        label="Geboortedatum"
                                        onChange={(date) => (user.$birthdate = date)}
                                        fullWidth
                                        required
                                        disableFuture
                                    />
                                    <CKFormFeedback>{errors['birthdate']}</CKFormFeedback>
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput
                                        type="email"
                                        name="email"
                                        label="Email"
                                        defaultValue={user.$email}
                                        onChange={(e) => (user.$email = e.target.value)}
                                        fullWidth
                                        required
                                        error={errors.hasOwnProperty('email')}
                                    />
                                    <CKFormFeedback>{errors['email']}</CKFormFeedback>
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput
                                        type="password"
                                        label="Wachtwoord"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        fullWidth
                                        required
                                        error={errors.hasOwnProperty('password')}
                                    />
                                    <CKFormFeedback>{errors['password']}</CKFormFeedback>
                                    <MKTypography variant="caption" sx={{ display: 'block' }}>
                                        8+ karakters met minstens 1 kleine letter, 1 hoofdletter en
                                        1 cijfer
                                    </MKTypography>
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput
                                        type="password"
                                        label="Bevestig wachtwoord"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        fullWidth
                                        required
                                        error={errors.hasOwnProperty('confirmPassword')}
                                    />
                                    <CKFormFeedback>{errors['confirmPassword']}</CKFormFeedback>
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKTypography variant="caption">Profielfoto</MKTypography>
                                    <CKEditImageField
                                        label="Profielfoto"
                                        getValue={() => user.$picture}
                                        setValue={(value) => {
                                            user.$picture = value
                                        }}
                                        model={user}
                                        urlValue={() => user.$pictureUrl}
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <CKDatePicker
                                        label="Begonnen met comedy"
                                        onChange={(date) => (user.$started_at = date)}
                                        fullWidth={true}
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput
                                        type="text"
                                        name="description"
                                        label="Beschrijving"
                                        defaultValue={user.$description}
                                        onChange={(e) => (user.$description = e.target.value)}
                                        fullWidth
                                        rows={4}
                                        multiline
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKTypography variant="button" color="text">
                                        Duidt aan wat van toepassing is op jou:
                                    </MKTypography>
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <MKTypography variant="button" color="dark">
                                            Open-Mic'er
                                        </MKTypography>
                                        <Switch
                                            defaultChecked={user.$isOpenMicer}
                                            color="info"
                                            onChange={(e) => {
                                                user.$isOpenMicer = e.target.checked
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <MKTypography variant="button" color="dark">
                                            MC
                                        </MKTypography>
                                        <Switch
                                            defaultChecked={user.$isMC}
                                            color="info"
                                            onChange={(e) => {
                                                user.$isMC = e.target.checked
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <MKTypography variant="button" color="dark">
                                            Organisator
                                        </MKTypography>
                                        <Switch
                                            defaultChecked={user.$isOrganizer}
                                            color="info"
                                            onChange={(e) => {
                                                user.$isOrganizer = e.target.checked
                                            }}
                                        />
                                    </div>
                                </MKBox>
                                <CKSocialsForm model={user} />
                                <MKBox mt={4} mb={1}>
                                    <MKButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        disabled={saving}
                                    >
                                        {saving ? (
                                            <ClipLoader size={15} color={'white'} />
                                        ) : (
                                            'Registreren'
                                        )}
                                    </MKButton>
                                </MKBox>
                                <MKBox mt={3} mb={1} textAlign="center">
                                    <MKTypography variant="button" color="text">
                                        Heb je al een account?{' '}
                                        <MKTypography
                                            component={Link}
                                            to="/login"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                        >
                                            Log in
                                        </MKTypography>
                                    </MKTypography>
                                </MKBox>
                            </MKBox>
                        </Card>
                    </Grid>
                </Grid>
            </MKBox>
        </>
    )
}

export default RegisterPage
