import logoDark from 'assets/images/logos/logo_dark.png'
import logoLight from 'assets/images/logos/logo_light.png'
import { BrandInfo, FooterLink } from './types'

export const FOOTER_LINKS: FooterLink[] = [
    { label: 'Home', href: '/' },
    { label: 'Artiesten', href: '/artists' },
    { label: 'Locaties', href: '/locations' },
    { label: 'Workshops', href: '/workshops' },
]

export const ABOUT_LINKS: FooterLink[] = [
    { label: 'Gebruikersvoorwaarden', href: '/terms-and-conditions' },
    { label: 'Privacybeleid', href: '/privacy' },
    { label: 'Vraag een functie aan / meld een fout', href: '/feature-request' },
]

export const BRAND: BrandInfo = {
    name: 'ComedyKit',
    image: logoLight,
    image_dark: logoDark,
    route: '/',
} 