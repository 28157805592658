import { Capacitor } from '@capacitor/core'
import { Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import useSize from '@react-hook/size'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ErrorIcon } from 'components/UI/CKIcons'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CKSidebarTabsProps } from './types'
import { findFirstErrorTab } from './utils'

export const CKDesktopSidebarTabs: FC<
    CKSidebarTabsProps & {
        hasNewErrors: boolean
        onErrorTabShown: () => void
    }
> = ({ sections, hideTitle, hideGroup, customFooter, hasNewErrors, onErrorTabShown }) => {
    const { search } = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(search)
    const urlTab = searchParams.get('tab')
    const [hasShownError, setHasShownError] = useState(false)

    // Initialize with URL tab or first tab
    const [selectedTab, setSelectedTab] = useState<string>(
        urlTab || Object.keys(sections[Object.keys(sections)[0]])[0]
    )

    // Set initial tab in URL if not present
    useEffect(() => {
        if (!urlTab) {
            const searchParams = new URLSearchParams(search)
            searchParams.set('tab', selectedTab)
            history.replace({
                pathname: history.location.pathname,
                search: '?' + searchParams.toString(),
            })
        }
    }, [selectedTab, search, history])

    // Handle error tabs and initial selection
    useEffect(() => {
        const errorTab = findFirstErrorTab(sections)
        if (errorTab && !hasShownError) {
            const searchParams = new URLSearchParams(search)
            searchParams.set('tab', errorTab)
            history.replace({
                pathname: history.location.pathname,
                search: '?' + searchParams.toString(),
            })
            setSelectedTab(errorTab)
            setHasShownError(true)
        }
    }, [sections]) // Only depend on sections changes

    useEffect(() => {
        if (hasNewErrors) {
            const errorTab = findFirstErrorTab(sections)
            if (errorTab) {
                const searchParams = new URLSearchParams(search)
                searchParams.set('tab', errorTab)
                history.replace({
                    pathname: history.location.pathname,
                    search: '?' + searchParams.toString(),
                })
                setSelectedTab(errorTab)
                onErrorTabShown()
            }
        }
    }, [hasNewErrors, sections])

    const onTabClicked = useCallback(
        (tab: string) => {
            if (tab === selectedTab) return
            const searchParams = new URLSearchParams(search)
            searchParams.set('tab', tab)
            setSelectedTab(tab)
            history.replace({
                pathname: history.location.pathname,
                search: '?' + searchParams.toString(),
            })
        },
        [selectedTab, search, history]
    )

    const renderContent = useMemo(() => {
        const section = Object.keys(sections).find((section) =>
            sections[section].hasOwnProperty(selectedTab)
        )
        return section ? sections[section][selectedTab].component : null
    }, [sections, selectedTab])

    const renderTitle = useMemo(() => {
        const section = Object.keys(sections).find((section) =>
            sections[section].hasOwnProperty(selectedTab)
        )
        return section ? sections[section][selectedTab].title : null
    }, [sections, selectedTab])

    const leftColumn = useRef(null)
    const [, height] = useSize(leftColumn)

    return (
        <Grid container>
            <Grid item xs={12} md={4} lg={3} className="ck-settings-menu py-2" ref={leftColumn}>
                <div className="d-flex flex-column justify-content-between h-100">
                    <List>
                        {Object.keys(sections).map((group) => {
                            const groupMenu = sections[group]
                            return (
                                <div className="mb-4" key={`settings-group-${group}`}>
                                    {!hideGroup && group}

                                    {Object.keys(groupMenu).map((key) => {
                                        const menuItem = groupMenu[key]
                                        if (
                                            menuItem.hasOwnProperty('onlyMobile') &&
                                            !Capacitor.isNativePlatform()
                                        ) {
                                            return null
                                        }
                                        return (
                                            <ListItemButton
                                                key={`ck-settings-menu-option-${key}`}
                                                className="ck-settings-menu-option"
                                                selected={selectedTab === key}
                                                onClick={() => onTabClicked(key)}
                                            >
                                                <ListItemIcon className="mt-1">
                                                    {menuItem.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <div className="d-flex align-items-center">
                                                            <span>{menuItem.title}</span>
                                                            {menuItem.hasErrors && (
                                                                <div className="ms-2">
                                                                    <ErrorIcon
                                                                        fontSize="small"
                                                                        color="error"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                    className="m-0 p-0"
                                                    primaryTypographyProps={{
                                                        fontSize: 'h6.fontSize',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '0.5rem',
                                                    }}
                                                />
                                            </ListItemButton>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </List>
                    {customFooter}
                </div>
            </Grid>
            <Grid item md={8} lg={9}>
                <MKBox
                    className="ck-settings-option-box py-2 px-3"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ boxShadow: 0 }}
                >
                    {!hideTitle && (
                        <MKTypography
                            sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}
                            className="setting-title text-center mb-4"
                        >
                            {renderTitle}
                        </MKTypography>
                    )}
                    {renderContent}
                </MKBox>
            </Grid>
        </Grid>
    )
}
