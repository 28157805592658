import { useUserContext, useUserContextDispatch } from 'contexts/UserContext'
import ComedyTag from 'models/ComedyTag'
import PendingImage from 'models/PendingImage'
import User from 'models/User'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Zoom, toast } from 'react-toastify'
import ComedyTagService from 'services/ComedyTagService'
import ImageService from 'services/ImageService'
import UserService, { useArtist } from '../UserService'

export function useArtistEdit(userId: string) {
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<Record<string, any>>({})
    const [saving, setSaving] = useState(false)
    const [selectedComedyTags, setSelectedComedyTags] = useState<ComedyTag[]>([])
    const [unselectedComedyTags, setUnselectedComedyTags] = useState<ComedyTag[]>([])

    const history = useHistory()
    const { user: loggedInUser } = useUserContext()
    const userDispatch = useUserContextDispatch()
    const { mutate } = useArtist(userId)

    useEffect(() => {
        const loadUser = async () => {
            if (!userId) return
            setLoading(true)
            try {
                if (userId === 'new') {
                    setUser(new User())
                } else {
                    const loadedUser = await UserService.get(userId)
                    if (!loadedUser.hasRight('FULL')) history.push(loadedUser.$detailUrl)
                    setUser(loadedUser)

                    // Load and setup comedy tags
                    const selectedTagsIds = loadedUser.$comedy_tags.map((tag) => tag.$id)
                    const allTags = await ComedyTagService.getAll()

                    setSelectedComedyTags(loadedUser.$comedy_tags)
                    setUnselectedComedyTags(
                        allTags.filter((tag) => !selectedTagsIds.includes(tag.$id))
                    )
                }
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadUser()
    }, [userId, history])

    const validate = () => {
        if (!user) return false
        const _errors = user.validate()
        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }

    const save = async () => {
        if (!user) return
        setSaving(true)
        try {
            if (!validate()) {
                toast.error('Gegevens incorrect', {
                    containerId: 'B',
                    transition: Zoom,
                    autoClose: 2000,
                })
                return
            }

            // Upload picture if it's a pending image
            if (user.$picture instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: user.$picture.$file,
                    name: `${user.$name} - PICTURE`,
                    type: 'LOGO',
                })
                user.$picture = image
            }

            // Upload banner if it's a pending image
            if (user.$banner instanceof PendingImage) {
                const image = await ImageService.upload({
                    file: user.$banner.$file,
                    name: `${user.$name} - BANNER`,
                    type: 'BANNER',
                })
                user.$banner = image
            }

            // Update comedy tags
            user.$comedy_tags = selectedComedyTags

            const updatedUser = await UserService.update(user)

            // Update global user state if editing logged in user
            if (updatedUser.$id === loggedInUser?.$id) {
                userDispatch({
                    type: 'REFRESH',
                    user: updatedUser,
                })
            }
            mutate()
            toast.success('Opgeslagen', {
                containerId: 'B',
                transition: Zoom,
                autoClose: 2000,
            })
            history.push(updatedUser.$detailUrl)
        } catch (e) {
            console.error(e)
        } finally {
            setSaving(false)
        }
    }

    const cancel = () => {
        history.push(user?.$detailUrl || '/')
    }

    return {
        user,
        loading,
        saving,
        errors,
        save,
        cancel,
        selectedComedyTags,
        setSelectedComedyTags,
        unselectedComedyTags,
        setUnselectedComedyTags,
    }
}
