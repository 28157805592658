import { Grid } from '@mui/material'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

export const Copyright: FC = () => (
    <Grid item xs={12} sx={{ textAlign: 'center' }} className="mt-1 pb-4">
        <MKTypography color="dark" variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {new Date().getFullYear()} Comedy Kit by{' '}
            <MKTypography
                component="a"
                href="https://www.bramkelchtermans.be"
                target="_blank"
                variant="button"
                color="dark"
                fontWeight="bold"
            >
                Bram Kelchtermans
            </MKTypography>{' '}
            &{' '}
            <MKTypography
                component="a"
                href="https://www.linkedin.com/in/daan-schouteden/"
                target="_blank"
                variant="button"
                color="dark"
                fontWeight="bold"
            >
                Daan Schouteden
            </MKTypography>
        </MKTypography>
    </Grid>
) 