import { isPlatform } from '@ionic/react'
import { Container, Grid } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import { FC } from 'react'
import { AboutColumn } from './components/AboutColumn'
import { Copyright } from './components/Copyright'
import { LinksColumn } from './components/LinksColumn'
import { LogoColumn } from './components/LogoColumn'

const CKFooter: FC = () => {
    const isMobileApp = isPlatform('android') || isPlatform('ios')

    if (isMobileApp) {
        return (
            <MKBox
                pt={10}
                px={1}
                style={{
                    backgroundColor: 'rgb(240, 242, 245)',
                    color: 'white',
                }}
            />
        )
    }

    return (
        <MKBox
            pt={12}
            px={1}
            style={{
                backgroundColor: 'rgb(240, 242, 245)',
                color: 'white',
            }}
        >
            <MKBox component="footer">
                <Container>
                    <Grid container spacing={3}>
                        <LogoColumn />
                        <LinksColumn />
                        <AboutColumn />
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        className="d-flex justify-content-center mt-3 mb-4"
                    >
                        <Copyright />
                    </Grid>
                </Container>
            </MKBox>
        </MKBox>
    )
}

export default CKFooter
